import { FC, useCallback, useEffect } from "react";
import { Button, notification } from "antd";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { Vehicle } from "../../vehicle/domain/type/Vehicle";
import { useVehicleViewModel } from "../../vehicle/presentation/ViewModel";
import { useDashboardHeader } from "../../../core/layout/dashboard/presentation/hook/Header";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { VehicleListData } from "./component/Data";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";

export const VehicleListPage: FC = () => {
  const params = useParams<Record<string, any>>();
  const { fetchList, vehicleList, fetchListState, onFetchListStateReceived } =
    useVehicleViewModel();
  const { setTitle } = useDashboardHeader();
  const fetchVehicleList = useCallback(() => {
    void fetchList(params.id);
  }, [params.id, fetchList]);

  const navigate = useNavigate();

  const onItemClicked = useCallback(
    (record: Vehicle) => {
      navigate("/dashboard/vehicles/" + record.id);
    },
    [navigate, vehicleList]
  );

  useEffect(() => {
    void fetchVehicleList();
    setTitle("Vehículos");
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState?.hasError) {
        notification.error({
          message: "Vehículos",
          description: fetchListState?.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader loading={!!fetchListState && fetchListState.loading} />
      <CustomBreadCrumb
        items={[
          {
            active: true,
            title: "...",
          },
        ]}
      />
      <ContentHeader
        title={"Vehículos"}
        subtitle={`${vehicleList?.length} vehículos`}
        extra={
          <Button.Group>
            <Button
              type={"primary"}
              ghost
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchVehicleList}
            >
              Actualizar
            </Button>
          </Button.Group>
        }
      />
      <div className={"w-full h-fit"}>
        <VehicleListData data={vehicleList} onItemClicked={onItemClicked} />
      </div>
    </ResponsiveContainer>
  );
};
