import { FC, useCallback, useEffect } from "react";
import { Button, notification } from "antd";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { CustomerUser } from "../../customer_user/domain/type/CustomerUser";
import { useCustomerUserViewModel } from "../../customer_user/presentation/ViewModel";
import { useDashboardHeader } from "../../../core/layout/dashboard/presentation/hook/Header";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";
import { UserListData } from "./component/Data";

export const UserListPage: FC = () => {
  const { fetchList, fetchListState, userList, onFetchListStateReceived } =
    useCustomerUserViewModel();
  const navigate = useNavigate();
  const { setTitle } = useDashboardHeader();

  const onDataItemClick = useCallback(
    (item: CustomerUser) => {
      navigate("/dashboard/users/" + item.id);
    },
    [navigate, userList]
  );

  useEffect(() => {
    void fetchList();
    setTitle("Usuarios");
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState?.hasError) {
        notification.error({
          message: "Usuarios",
          description: fetchListState?.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader loading={!!fetchListState && fetchListState.loading} />
      <CustomBreadCrumb
        items={[
          {
            active: true,
            title: "...",
          },
        ]}
      />
      <ContentHeader
        title={"Usuarios"}
        subtitle={userList?.length + " usuarios"}
        extra={
          <Button.Group>
            <Button
              type={"primary"}
              ghost
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchList}
            >
              Actualizar
            </Button>
          </Button.Group>
        }
      />
      <UserListData onItemClicked={onDataItemClick} data={userList} />
    </ResponsiveContainer>
  );
};
