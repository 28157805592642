import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import {
  GatewayDevice,
  GatewayDeviceSelection,
} from "../../domain/type/GatewayDevice";
import { ApiService } from "../../../../app/service/RemoteClient";

export class RemoteGatewayDeviceSource extends BaseRemoteSource {
  static async getGatewayDevices(
    id: number,
    selection?: GatewayDeviceSelection
  ): Promise<RemoteSourceResponse<Array<GatewayDevice>>> {
    try {
      const response = await ApiService.postWithAuth("/gateway_device/get", {
        id,
        selection,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Array<GatewayDevice>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
