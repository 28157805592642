import { useState } from "react";
import {
  GatewayDevice,
  GatewayDeviceSelection,
} from "../domain/type/GatewayDevice";
import { GatewayDeviceRepository } from "../data/repository/GatewayDevice";
import { AppTaskState } from "../../../app/domain/type/State";
import { AppTaskStateG } from "../../../app/domain/model/State";
import { EntityPicture } from "../../../core/domain/type/EntityPicture";
import { EntityPictureRepository } from "../../../core/data/repository/EntityPicture";

export function useGatewayDeviceViewModel() {
  const [fetchListState, setFetchListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [fetchPictureState, setFetchPictureState] =
    useState<AppTaskState<boolean> | null>(null);
  const [gatewayDeviceList, setGatewayDeviceList] = useState<
    GatewayDevice[] | null
  >(null);
  const [gatewayDevicePicture, setGatewayDevicePicture] =
    useState<EntityPicture | null>(null);

  async function fetchList(id: number, selection?: GatewayDeviceSelection) {
    if (fetchListState?.loading) return;
    setFetchListState(AppTaskStateG.loading());
    try {
      const list = await GatewayDeviceRepository.getGatewayDevices(
        id,
        selection
      );
      if (list.ok) {
        setGatewayDeviceList(list.data!!);
        setFetchListState(AppTaskStateG.success(true));
      } else setFetchListState(AppTaskStateG.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchListState(AppTaskStateG.error(error));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  async function fetchPicture(gatewayKey: string) {
    if (fetchPictureState?.loading) return;
    setFetchPictureState(AppTaskStateG.loading());
    try {
      const response = await EntityPictureRepository.getEntityPicture(
        `g_device/${gatewayKey}`
      );
      setFetchPictureState(AppTaskStateG.success(true));
      setGatewayDevicePicture(response.data!!);
    } catch (e: any) {
      setFetchPictureState(AppTaskStateG.error(e));
    }
  }

  function onFetchPictureStateReceived() {
    setFetchPictureState(null);
  }

  return {
    fetchListState,
    fetchList,
    onFetchListStateReceived,
    gatewayDeviceList,
    fetchPictureState,
    fetchPicture,
    onFetchPictureStateReceived,
    gatewayDevicePicture,
  };
}
