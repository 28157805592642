import { RemoteDashboardSource } from "../source/Remote";

export class DashboardRepository {
  static async getAllowedRoutes() {
    return await RemoteDashboardSource.getAllowedRoutes();
  }

  static async getConnectedUsers() {
    return await RemoteDashboardSource.getConnectedUsers();
  }

  static async getConnectedGateways() {
    return await RemoteDashboardSource.getConnectedGateways();
  }
}
