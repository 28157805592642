import { useState } from "react";
import { AuthRepository } from "../data/repository/Auth";
import { CustomerRepository } from "../../customer/data/repository/Customer";
import { AppTaskState } from "../../../app/domain/type/State";
import { Customer } from "../../customer/domain/type/Customer";
import { AppTaskStateG } from "../../../app/domain/model/State";
import { LoginData, PostLoginData } from "../domain/type/Login";
import { FindCustomerByEmailData } from "../domain/type/Customer";

export function useLoginViewModel() {
  const [loginState, setLoginState] = useState<AppTaskState<boolean> | null>(
    null
  );
  const [postLoginState, setPostLoginState] =
    useState<AppTaskState<boolean> | null>(null);
  const [resendCodeState, setResendCodeState] =
    useState<AppTaskState<boolean> | null>(null);
  const [loginDataSnapshot, setLoginDataSnapshot] = useState<LoginData | null>(
    null
  );
  const [fetchCustomerByEmailState, setFetchCustomerByEmailState] =
    useState<AppTaskState<Customer> | null>(null);
  const [loginEmail, setLoginEmail] = useState<string | null>(null);

  async function login(data: LoginData) {
    setLoginDataSnapshot(data);
    setLoginState(AppTaskStateG.loading());
    try {
      const result = await AuthRepository.login(data);
      if (result?.data?.mfa) {
        setLoginState(AppTaskStateG.success(true));
      } else {
        setLoginState(AppTaskStateG.success(true));
        setPostLoginState(AppTaskStateG.success(true));
      }
      return true;
    } catch (e: any) {
      setLoginState(AppTaskStateG.error(e));
    }
  }

  function onLoginStateReceived() {
    setLoginState(null);
  }

  async function verificateCode(data: PostLoginData) {
    setPostLoginState(AppTaskStateG.loading());
    try {
      await AuthRepository.postLogin(data);
      setPostLoginState(AppTaskStateG.success(true));
      return true;
    } catch (e: any) {
      setPostLoginState(AppTaskStateG.error(e));
    }
  }

  function onPostLoginStateReceived() {
    setPostLoginState(null);
  }

  async function resendCode() {
    setResendCodeState(AppTaskStateG.loading());
    const data = loginDataSnapshot;
    if (data === null || !data) {
      setResendCodeState(
        AppTaskStateG.error(
          new Error("La sesión no es válida. Inicia sesión nuevamente.")
        )
      );
    } else {
      try {
        await AuthRepository.login(data);
        setResendCodeState(AppTaskStateG.success(true));
      } catch (e: any) {
        setResendCodeState(AppTaskStateG.error(e));
      }
    }
  }

  function onResendCodeStateReceived() {
    setResendCodeState(null);
  }

  async function fetchCustomerByEmail({ email }: FindCustomerByEmailData) {
    if (fetchCustomerByEmailState?.loading) return;
    setFetchCustomerByEmailState(AppTaskStateG.loading());
    try {
      const style = await CustomerRepository.getCustomerStyleByEmail(email);
      if (style.ok) {
        setFetchCustomerByEmailState(AppTaskStateG.success(style.data!!));
        setLoginEmail(email);
        return true;
      } else {
        setFetchCustomerByEmailState(
          AppTaskStateG.error(
            new Error(style?.message ?? "Ha ocurrido un error.")
          )
        );
      }
    } catch (e: any) {
      setFetchCustomerByEmailState(AppTaskStateG.error(e));
    }
  }

  function onFetchCustomerByEmailStateReceived() {
    setFetchCustomerByEmailState(null);
  }

  function resetLoginEmail() {
    setLoginEmail(null);
  }

  return {
    loginState,
    postLoginState,
    login,
    onLoginStateReceived,
    verificateCode,
    onPostLoginStateReceived,
    onResendCodeStateReceived,
    resendCodeState,
    resendCode,
    fetchCustomerByEmailState,
    fetchCustomerByEmail,
    onFetchCustomerByEmailStateReceived,
    loginEmail,
    resetLoginEmail,
  };
}
