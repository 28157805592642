import { useState } from "react";
import { AppTaskState } from "../../../app/domain/type/State";
import { GatewayLocation } from "../../domain/type/DeviceData";
import { AppTaskStateG } from "../../../app/domain/model/State";
import { LocationRepository } from "../../data/repository/Location";
import { GeocodeAddress } from "nominatim-browser";
export function useLocationViewModel() {
  const [fetchAddressState, setFetchAddressState] =
    useState<AppTaskState<boolean> | null>(null);
  const [address, setAddress] = useState<GeocodeAddress | null>(null);
  async function fetchAddress(location: GatewayLocation) {
    if (fetchAddressState?.loading) return;
    setFetchAddressState(AppTaskStateG.loading());
    try {
      const res = await LocationRepository.getAddress(location);
      console.log(res);
      if (res!!.error)
        setFetchAddressState(AppTaskStateG.error(new Error(res!!.error)));
      else setAddress(res!!.address);
      setFetchAddressState(AppTaskStateG.success(true));
    } catch (e: any) {
      setAddress(null);
      setFetchAddressState(AppTaskStateG.error(e));
    }
  }
  function onFetchAddressStateReceived() {
    setFetchAddressState(null);
  }

  return {
    onFetchAddressStateReceived,
    fetchAddressState,
    fetchAddress,
    address,
  };
}
