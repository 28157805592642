import { useState } from "react";
import { GatewayRepository } from "../../gateway/data/repository/Gateway";
import { AppTaskState } from "../../../app/domain/type/State";
import { Gateway, OnlineGateway } from "../../gateway/domain/type/Gateway";
import { AppTaskStateG } from "../../../app/domain/model/State";

export function useGatewayViewModel() {
  const [fetchListState, setFetchListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [gatewayList, setGatewayList] = useState<Gateway[] | null>(null);
  const [fetchGatewayState, setFetchGatewayState] =
    useState<AppTaskState<boolean> | null>(null);
  const [vehicleGateway, setVehicleGateway] = useState<Array<Gateway> | null>(
    null
  );
  const [gateway, setGateway] = useState<OnlineGateway | null>(null);

  async function fetchList() {
    if (fetchListState?.loading) return;
    setFetchListState(AppTaskStateG.loading());
    try {
      const list = await GatewayRepository.getAllGateways();
      if (list.ok) {
        setGatewayList(list.data!!);
        setFetchListState(AppTaskStateG.success(true));
      } else setFetchListState(AppTaskStateG.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchListState(AppTaskStateG.error(error));
    }
  }

  async function fetchVehicleList(vehicleId: number) {
    if (fetchListState?.loading) return;
    setFetchListState(AppTaskStateG.loading());
    try {
      const list = await GatewayRepository.getVehicleGatewayList(vehicleId);
      if (list.ok) {
        setVehicleGateway(list.data!!);
        setFetchListState(AppTaskStateG.success(true));
      } else setFetchListState(AppTaskStateG.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchListState(AppTaskStateG.error(error));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  async function fetchGateway(id: number) {
    if (fetchGatewayState?.loading) return;
    setFetchGatewayState(AppTaskStateG.loading());
    try {
      const gatewayData = await GatewayRepository.getGateway(id);
      if (gatewayData.ok) {
        setFetchGatewayState(AppTaskStateG.success(true));
        setGateway(gatewayData.data!!);
      } else {
        setFetchGatewayState(
          AppTaskStateG.error(new Error(gatewayData.message!!))
        );
      }
    } catch (e: any) {
      setFetchGatewayState(AppTaskStateG.error(e));
    }
  }

  function onFetchGatewayStateReceived() {
    setFetchGatewayState(null);
  }

  return {
    fetchListState,
    gatewayList,
    fetchList,
    onFetchListStateReceived,
    fetchGatewayState,
    fetchGateway,
    onFetchGatewayStateReceived,
    gateway,
    vehicleGateway,
    fetchVehicleList,
  };
}
