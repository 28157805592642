import { RemoteSourceResponse } from "../../../../domain/type/Source";
import { BaseRemoteSource } from "../../../../domain/model/Remote";

import { ApiService } from "../../../../../app/service/RemoteClient";
import { DashboardAllowedRoutes } from "../../domain/type/AllowedRoutes";
import { CustomerUser } from "../../../../../features/customer_user/domain/type/CustomerUser";
import {
  Gateway,
  OnlineGateway,
} from "../../../../../features/gateway/domain/type/Gateway";

export class RemoteDashboardSource extends BaseRemoteSource {
  static async getAllowedRoutes(): Promise<
    RemoteSourceResponse<DashboardAllowedRoutes> | undefined
  > {
    try {
      const role = await ApiService.getWithAuth("/dashboard/allowed_routes");
      this.checkResponseCredentials(role);
      return role.data as RemoteSourceResponse<DashboardAllowedRoutes>;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async getConnectedUsers(): Promise<
    RemoteSourceResponse<Array<CustomerUser>>
  > {
    try {
      const connectedUsersResponse = await ApiService.getWithAuth(
        "/dashboard/connected_users"
      );
      this.checkResponseCredentials(connectedUsersResponse);
      return connectedUsersResponse.data as RemoteSourceResponse<
        Array<CustomerUser>
      >;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getConnectedGateways(): Promise<
    RemoteSourceResponse<Array<OnlineGateway>>
  > {
    try {
      const connectedGatewaysResponse = await ApiService.getWithAuth(
        "/dashboard/connected_gateways"
      );
      this.checkResponseCredentials(connectedGatewaysResponse);
      return connectedGatewaysResponse.data as RemoteSourceResponse<
        Array<OnlineGateway>
      >;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
