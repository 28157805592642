import {
  FC,
  MouseEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Avatar } from "antd";
import { MapContainer, TileLayer } from "react-leaflet";
import { Map, Marker, divIcon, marker } from "leaflet";
import stringToColor from "string-to-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faTruckFast } from "@fortawesome/free-solid-svg-icons";
import { renderToString } from "react-dom/server";
import { ReactComponent } from "../../../assets/image/marker.svg";
import { Gateway } from "../../../features/gateway/domain/type/Gateway";
import { GatewayLocation } from "../../domain/type/DeviceData";
import { SocketIOService } from "../../../app/service/SocketIO";
import { FullscreenToggle } from "./Fullscreen";

export const MultipleGatewayMap: FC<{
  gatewayList?: Array<Gateway>;
}> = ({ gatewayList }) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const map = useRef<Map>(null);
  const markers = useRef<{ [key: string]: Marker }>({});
  const [restoreSize, setRestoreSize] = useState<boolean>(false);

  const listeners = useMemo(() => {
    const currentGatewayList = gatewayList;
    return currentGatewayList?.map((it) => {
      return {
        event: `data/${it.key}/gps_live`,
        callback: (data: GatewayLocation) => {
          const currentMarker = markers.current[it.key];
          if (!!currentMarker) {
            currentMarker.setLatLng([data.latitude, data.longitude]);
          } else {
            const marker = new Marker([data.latitude, data.longitude], {
              icon: divIcon({
                iconSize: [32, 32],
                iconAnchor: [16, 32],
                html: renderToString(
                  <div className="w-full h-full relative">
                    <ReactComponent
                      style={{
                        fill: stringToColor(it!!.key),
                      }}
                      className="w-full h-full"
                    />
                    <div className="inset-0 mx-auto mt-1 absolute h-1/2 bg-transparent rounded-full aspect-square fill-white text-white">
                      <FontAwesomeIcon icon={faTruck} />
                    </div>
                  </div>
                ),
                className: "bg-transparent",
              }),
              pane: "markerPane",
              title: it.Vehicle?.name,
            });
            marker.addTo(map.current!);
            markers.current[it.key] = marker;
          }
        },
      };
    });
  }, [gatewayList, markers, map]);

  useEffect(() => {
    const currentListeners = listeners;
    currentListeners?.forEach((it) => {
      SocketIOService.socketOn(it.event, it.callback);
    });
    return () => {
      currentListeners?.forEach((it) => {
        SocketIOService.socketOff(it.event, it.callback);
      });
    };
  }, [listeners]);

  const containerRef = useRef<HTMLDivElement>(null);

  const cardClickCallback: MouseEventHandler<HTMLDivElement> = useCallback(
    (element) => {
      const key = element.currentTarget.dataset.key;
      const currentMarker = markers.current[key!];
      if (!!currentMarker) {
        map.current?.panTo(currentMarker.getLatLng());
      }
    },
    [markers, map]
  );

  useEffect(() => {
    setRestoreSize(true);
    return () => {
      setRestoreSize(false);
      //const currentMap = map.current;
      //currentMap?.remove();
      (map as MutableRefObject<Map | null>).current = null;
    };
  }, []);

  useEffect(() => {
    if (!!map?.current) {
      const current = timeoutRef.current;
      if (!!current) {
        clearTimeout(current);
      }
      timeoutRef.current = setTimeout(() => {
        if (map?.current?.invalidateSize) {
          map?.current?.invalidateSize();
        }
      }, 200);
    }
  }, [restoreSize]);
  return (
    <div
      className={"w-full h-full"}
      ref={containerRef}
      style={{
        maxHeight: "none",
      }}
    >
      <div
        className={"absolute right-0 top-0 z-50 flex flex-col px-4 py-2 gap-2"}
      >
        <FullscreenToggle
          className="ml-auto"
          type="default"
          containerRef={containerRef}
        />

        {gatewayList && (
          <div className="rounded-lg bg-white dark:bg-neutral-600 dark:bg-opacity-75 bg-opacity-75 divide-neutral-300 divide-y divide-solid divide-x-0">
            {gatewayList.map((it) => (
              <div
                key={`${it.key}_locb`}
                className="py-2 px-3 flex flex-row items-center justify-start gap-2"
                data-key={it.key}
                onClick={cardClickCallback}
              >
                <Avatar
                  style={{
                    backgroundColor: stringToColor(it.key),
                  }}
                >
                  <FontAwesomeIcon icon={faTruckFast} />
                </Avatar>
                {it.Vehicle?.name}
              </div>
            ))}
          </div>
        )}
      </div>

      <MapContainer
        style={{
          width: "100%",
          height: "100%",
          maxHeight: "none",
          zIndex: 10,
          position: "absolute",
          top: 0,
          left: 0,
        }}
        className="w-full h-full"
        zoom={7}
        scrollWheelZoom={false}
        ref={map}
        center={[-12.04318, -77.02824]}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
};
