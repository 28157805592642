import { FC, PropsWithChildren, createContext, useState } from "react";

type Value = {
  title: string | undefined;
  setTitle: (newTitle: string | undefined) => void;
};

export const DashboardHeaderContext = createContext<Value>({
  title: "",
  setTitle: null!,
});

export const DashboardHeaderProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [title, setTitle] = useState<string | undefined>("");

  return (
    <DashboardHeaderContext.Provider
      value={{
        title,
        setTitle,
      }}
    >
      {children}
    </DashboardHeaderContext.Provider>
  );
};
