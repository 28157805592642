import { ThemeConfig, theme } from "antd/lib";
import { AppAntThemeGenerator } from "../model/ThemeGenerator";

export class DarkThemeGenerator extends AppAntThemeGenerator {
  override build(): ThemeConfig {
    const {
      colorPrimary,
      colorSecondary,
      textColorSecondary,
      colorTertiary,
      textColorOnTertiary,
      textColorOnPrimary,
      textColorOnSecondary,
    } = this.palette;

    return {
      algorithm: theme.compactAlgorithm,
      token: {
        colorPrimary: colorPrimary,
        colorBorderSecondary: "rgba(255,255,255,0)",
        colorBorder: "rgba(255,255,255,0)",
        colorBgBase: "#212121",
        colorLink: colorPrimary,
        linkDecoration: "underline",
        colorText: "#EFEFEF",
        colorTextSecondary: "#EFEFEF",
        colorTextBase: "#FFFFFF",
        fontFamily: "'Roboto', sans-serif",
      },
      components: {
        Layout: {
          headerBg: "transparent",
          triggerBg: "transparent",
          triggerColor: textColorSecondary,
          bodyBg: "transparent",
          colorBgContainer: "#ffffff",
          siderBg: "transparent",
          lightSiderBg: colorTertiary,
          lightTriggerBg: "transparent",
          lightTriggerColor: textColorOnTertiary,
          triggerHeight: 65,
        },
        Drawer: {
          colorIcon: "#BDBDBD",
          colorText: "#BDBDBD",
          colorIconHover: "#424242",
          colorSplit: "rgba(255,255,255,0.5)",
          colorBgElevated: "#fff",
        },
        Table: {
          colorBorder: "rgba(28,44,84,0.15)",
          colorBorderBg: "rgba(28,44,84,0.15)",
          colorBorderSecondary: "rgba(28,44,84,0.15)",
          colorBgTextHover: colorPrimary,
          colorText: "#BDBDBD",
          headerBg: colorSecondary,
          headerColor: textColorOnSecondary,
          rowHoverBg: "rgba(28,44,84,0.1)",
          headerFilterHoverBg: "#BDBDBD",
          colorIcon: textColorOnSecondary,
          colorPrimaryTextHover: "#BDBDBD",
        },
        Input: {
          colorText: "#505050",
        },
        Alert: {
          colorInfoBg: "#ccf7ff",
          colorErrorBg: "#ffe0e0",
          colorErrorTextActive: "#640000",
          colorError: "#640000",
          colorErrorBorder: "#640000",
          colorWarningBg: "#f5eac5",
          colorInfoText: "#212121",
          colorTextBase: "#212121",
        },
        Card: {
          colorBgContainer: "#212121",
          colorBgElevated: "#212121",
          boxShadowTertiary: "1px 1px 3px rgba(0,0,0,0.13)",
          zIndexBase: 20,
          zIndexPopupBase: 20,
        },

        Progress: {
          colorText: textColorOnPrimary,
          colorTextBase: textColorOnPrimary,
          circleTextColor: textColorOnPrimary,
          colorInfoText: textColorOnPrimary,
          colorPrimaryText: textColorOnPrimary,
          colorTextLabel: textColorOnPrimary,
          colorTextSecondary: textColorOnPrimary,
        },
        Divider: {
          colorSplit: "#666666",
        },
      },
    };
  }
}
