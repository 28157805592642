import { FC, HTMLProps, MutableRefObject, PropsWithChildren } from "react";
import { Layout } from "antd";

export type TabContentBoxProps = PropsWithChildren<{
  className?: HTMLProps<HTMLDivElement>["className"];
  style?: HTMLProps<HTMLDivElement>["style"];
  divRef?: MutableRefObject<HTMLDivElement>;
}>;
export const TabContentBox: FC<TabContentBoxProps> = ({
  style,
  className,
  children,
  divRef,
}) => {
  return (
    <Layout.Content
      className={`relative overflow-visible mx-auto sm:max-w-md md:max-w-3xl  lg:max-w-4xl xl:max-w-5xl w-full h-fit px-2 md:px-4 ${className}`}
      style={style}
      ref={divRef}
    >
      {children}
    </Layout.Content>
  );
};
