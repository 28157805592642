import { FC } from "react";
import { Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTag,
  faHashtag,
  faBuilding,
  faIdCard,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { Vehicle } from "../../../domain/type/Vehicle";
import { ResponsiveContainer } from "../../../../../core/presentation/component/Container";
import {
  DescriptionsItem,
  DescriptionsList,
} from "../../../../../core/presentation/component/DescriptionsList";
import { DateUtil } from "../../../../../core/util/Date";
import { CustomNavigator } from "../../../../../core/presentation/component/CustomLink";

type Props = {
  vehicle: Vehicle;
};

export const VehicleMainTab: FC<Props> = ({ vehicle }) => {
  return (
    <ResponsiveContainer>
      <DescriptionsList rowKey={"vehicle"}>
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Nombre"}
          label={vehicle.name}
          itemKey={"name"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faHashtag} />}
          title={"ID"}
          label={vehicle.id}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Marca"}
          label={vehicle.brand}
          itemKey={"brand"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Modelo"}
          label={vehicle.model}
          itemKey={"model"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faIdCard} />}
          title={"Placa"}
          label={vehicle.plate}
          itemKey={"plate"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Fecha registro"}
          label={DateUtil.fastFormatDate(
            vehicle.createdAt,
            "dd 'de' MMMM yyyy"
          )}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Descripción"}
          label={vehicle.description}
          itemKey={"description"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faToggleOn} />}
          title={"Estado"}
          label={
            vehicle?.enabled ? "Vehículo habilitado" : "Vehículo deshabilitado"
          }
          itemKey={"enabled"}
        />
        <CustomNavigator to={`/dashboard/customers/${vehicle.Customer.id}`}>
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faBuilding} />}
            title={"Cliente"}
            content={
              <Typography.Link>{vehicle.Customer.businessName}</Typography.Link>
            }
          />
        </CustomNavigator>
      </DescriptionsList>
    </ResponsiveContainer>
  );
};
