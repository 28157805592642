import { ChartData1, ChartSchemaDefinition } from "../../../domain/type/Chart";
import { BaseLinearChart } from "./BaseLinear";

type Props = {
  tooltipTitle: string;
  items: Array<ChartData1<number>>;
  id: string;
  schema: ChartSchemaDefinition;
};

export function BarChart2(props: Props) {
  return <BaseLinearChart {...props} type={"line"} />;
}
