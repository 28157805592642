import { FC, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faLocation,
  faNetworkWired,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import { Gateway, OnlineGateway } from "../../domain/type/Gateway";
import {
  TabContainer,
  TabContainerItem,
} from "../../../../core/presentation/component/TabContainer";
import { useDashboardHeader } from "../../../../core/layout/dashboard/presentation/hook/Header";
import { GatewayDevicesTab } from "./tabs/TabDevices";
import { GatewayActionsTab } from "./tabs/actions";
import { GatewayMainTab } from "./tabs/TabMain";
import { GatewayLocationTab } from "./tabs/TabLocation";

type Props = {
  gateway: OnlineGateway;
};

const items: TabContainerItem[] = [
  {
    key: "main",
    icon: <FontAwesomeIcon icon={faHome} />,
    label: "Principal",
  },
  {
    key: "location",
    icon: <FontAwesomeIcon icon={faLocation} />,
    label: "Ubicación",
  },
  {
    key: "actions",
    label: "Acciones",
    icon: <FontAwesomeIcon icon={faTasks} />,
  },
  {
    key: "devices",
    label: "Variables",
    icon: <FontAwesomeIcon icon={faNetworkWired} />,
  },
];

export const GatewayDataComponent: FC<Props> = ({ gateway }) => {
  const { setTitle } = useDashboardHeader();

  useEffect(() => {
    setTitle("Gateway");
  }, []);
  const onSelected = useCallback((key: string) => {
    switch (key) {
      case "devices":
        return <GatewayDevicesTab gateway={gateway} />;
      case "actions":
        return <GatewayActionsTab gateway={gateway} />;
      case "location":
        return <GatewayLocationTab gateway={gateway} />;
      default:
        return <GatewayMainTab gateway={gateway} />;
    }
  }, []);

  return (
    <TabContainer
      items={items}
      defaultSelectedKey={"main"}
      onSelected={onSelected}
    />
  );
};
