import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

export const LiveDot: FC = () => (
  <span className="relative flex h-6 w-6">
    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
    <span className="relative inline-flex rounded-full h-6 w-6 bg-green-500 text-white p-1">
      <FontAwesomeIcon icon={faSignal} className="w-full m-full" />
    </span>
  </span>
);
