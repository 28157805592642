import { App as AntApp } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { HappyProvider } from "@ant-design/happy-work-theme";
import { AppComponent } from "./component/AppComponent";
import { AppThemeProvider } from "../context/Theme";
import { AppCustomerProvider } from "../context/Customer";
import { AppResponsiveScreenProvider } from "../context/Responsive";
import { AppUserProvider } from "../context/User";
import { Provider } from "react-redux";
import { store } from "../domain/model/Store";

dayjs.locale("es");

function App() {
  return (
    <Provider store={store}>
      <AntApp>
        <HappyProvider>
          <AppResponsiveScreenProvider>
            <AppCustomerProvider>
              <AppThemeProvider>
                <AppUserProvider>
                  <AppComponent />
                </AppUserProvider>
              </AppThemeProvider>
            </AppCustomerProvider>
          </AppResponsiveScreenProvider>
        </HappyProvider>
      </AntApp>
    </Provider>
  );
}

export default App;
