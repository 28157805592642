import { FC } from "react";
import { Progress } from "antd/lib";
import { ProgressProps } from "antd";
import { useAppTheme } from "../../../app/hook/Theme";

export const CircularProgress: FC<ProgressProps> = (props) => {
  const { isDarkThemeEnabled } = useAppTheme();
  return (
    <Progress
      size={40}
      type={"circle"}
      strokeColor={isDarkThemeEnabled ? "#FAFAFA" : "#212121"}
      trailColor={isDarkThemeEnabled ? "#737373" : "#b0b0b0"}
      style={{
        color: "#FAFAFA",
      }}
      showInfo={false}
      rootClassName={"text-white"}
      className={"text-primary"}
      {...props}
    />
  );
};
