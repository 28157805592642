import { FC, useCallback } from "react";
import { Typography, Button } from "antd";
import * as yup from "yup";
import useLocalStorageState from "use-local-storage-state";
import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faAt,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, FormikHelpers } from "formik";
import { Form, Input, FormItem, SubmitButton, Checkbox } from "formik-antd";
import { LoginData } from "../../domain/type/Login";
import { CheckboxChangeEvent } from "antd/es/checkbox";

type Props = {
  onSubmit: (values: LoginData) => Promise<boolean | undefined>;
  onBack: () => void;
  initialData: Partial<LoginData>;
};

const kAUTH_FORM_VALIDATOR = yup.object({
  email: yup
    .string()
    .required("Ingresa un email")
    .email("Ingresa un email válido"),
  password: yup.string().required("Ingresa una contraseña"),
});

export const LoginForm: FC<Props> = ({ onSubmit, onBack, initialData }) => {
  const [rememberSession, setRememberSession] = useLocalStorageState(
    "rememberMe",
    {
      defaultValue: false,
    }
  );

  const onSubmitCaptured = useCallback(
    (
      values: Partial<LoginData>,
      formikHelpers: FormikHelpers<Partial<LoginData>>
    ) => {
      onSubmit(values as LoginData).then((it) => {
        if (it) formikHelpers.resetForm();
        formikHelpers.setSubmitting(false);
      });
    },
    [onSubmit]
  );
  const onChangeRememberMe = useCallback(
    (e: CheckboxChangeEvent) => {
      setRememberSession(e.target.checked);
    },
    [onSubmitCaptured]
  );
  return (
    <div className={"flex flex-col justify-start items-start w-full gap-4"}>
      <span className="text-neutral-800 dark:text-neutral-200 text-lg uppercase font-medium">
        Iniciar Sesión
      </span>
      <Formik<Partial<LoginData>>
        validateOnChange
        initialValues={{ ...initialData, rememberSession }}
        validationSchema={kAUTH_FORM_VALIDATOR}
        onSubmit={onSubmitCaptured}
      >
        <Form layout={"vertical"} className="w-full">
          <FormItem name={"email"}>
            <Input
              name={"email"}
              disabled={!!initialData.email}
              prefix={<FontAwesomeIcon icon={faAt} />}
              placeholder={"Correo Electrónico"}
            />
          </FormItem>
          <FormItem name={"password"}>
            <Input.Password
              allowClear
              name={"password"}
              placeholder={"Contraseña"}
            />
          </FormItem>
          <FormItem name={"rememberSession"}>
            <Checkbox name="rememberSession" onChange={onChangeRememberMe}>
              Mantener sesión iniciada
            </Checkbox>
          </FormItem>

          <div className="flex flex-col gap-2">
            <RouterLink to={"/recover-password"} className={"no-underline"}>
              <Typography.Text className="text-primary" underline={false}>
                ¿Olvidaste tu contraseña? Click aquí
              </Typography.Text>
            </RouterLink>
            <Button.Group className="mr-auto">
              <Button
                type="default"
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
                onClick={onBack}
              >
                Atrás
              </Button>
              <SubmitButton
                type="primary"
                icon={<FontAwesomeIcon icon={faPaperPlane} />}
              >
                Iniciar Sesión
              </SubmitButton>
            </Button.Group>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
