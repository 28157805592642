import { FC, ReactNode } from "react";
import { Typography } from "antd";
import { useAppResponsiveScreen } from "../../../app/hook/Breakpoint";

type Props = {
  title?: string;
  subtitle?: string;
  extra?: ReactNode;
};
export const ContentHeader: FC<Props> = ({ title, extra, subtitle }) => {
  const { isMobileScreen } = useAppResponsiveScreen();
  return (
    <div
      className={`w-full p-2 md:p-4 lg:my-2 flex flex-col sm:flex-row flex-wrap items-end relative`}
    >
      <div className={"flex flex-col"}>
        {title && (
          <Typography.Title
            style={{
              padding: 0,
              margin: 0,
            }}
            className="font-quicksand"
            level={isMobileScreen ? 5 : 4}
          >
            {title}
          </Typography.Title>
        )}
        {subtitle ? (
          <Typography.Text
            style={{
              padding: 0,
              margin: 0,
            }}
            type={"secondary"}
          >
            {subtitle}
          </Typography.Text>
        ) : null}
      </div>
      <div
        className={"h-fit w-fit flex place-items-end place-content-end ml-auto"}
      >
        {extra}
      </div>
    </div>
  );
};
