import { Navigate, RouteObject } from "react-router-dom";
import { RouteGenerator } from "../../../../domain/model/RouteGenerator";
import { MainLayout } from "../../presentation/Layout";
import { DashboardPaths } from "../../../dashboard/domain/implementation/Paths";
import { MainPaths } from "./Paths";
import { AuthPage } from "../../../../../features/auth/presentation/Page";
import { AuthRepository } from "../../../../../features/auth/data/repository/Auth";
import { ForgotPasswordPage } from "../../../../../features/forgot_password/presentation/Page";
import { PasswordUpdatePage } from "../../../../../features/update_password/presentation/Page";

export class MainRouteGenerator extends RouteGenerator {
  override build(): RouteObject[] {
    return [
      {
        path: MainPaths.root,
        element: <MainLayout />,
        children: [
          {
            path: MainPaths.root,
            element: <Navigate to={DashboardPaths.overview!!} />,
          },
          {
            path: MainPaths.auth,
            errorElement: <AuthPage />,
            loader: async () => AuthRepository.getSession(),
            element: <Navigate to={DashboardPaths.overview!!} />,
          },
          {
            path: MainPaths.password_recovery,
            errorElement: <ForgotPasswordPage />,
            loader: async () => AuthRepository.getSession(),
            element: <Navigate to={DashboardPaths.overview!!} />,
          },
          {
            path: MainPaths.password_update,
            errorElement: <PasswordUpdatePage />,
            loader: async () => AuthRepository.getSession(),
            element: <Navigate to={DashboardPaths.overview!!} />,
          },
        ],
      },
    ];
  }
}
