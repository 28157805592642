import { createModel } from "@rematch/core";
import { RootModel } from "../../../../app/domain/model/AppState";
import { CustomerUser } from "../../../customer_user/domain/type/CustomerUser";
import { Gateway, OnlineGateway } from "../../../gateway/domain/type/Gateway";
interface OverviewState {
  connectedUsers: Array<CustomerUser>;
  connectedGateways: Array<OnlineGateway>;
  layoutDragLocked: boolean;
}

export const overview = createModel<RootModel>()({
  state: {
    connectedUsers: [],
    connectedGateways: [],
    layoutDragLocked: false,
  } as OverviewState,
  reducers: {
    updateConnectedUsers(
      state: OverviewState,
      connectedUsers: Array<CustomerUser>
    ) {
      return {
        ...state,
        connectedUsers,
      };
    },
    updateConnectedGateways(
      state: OverviewState,
      connectedGateways: Array<OnlineGateway>
    ) {
      return {
        ...state,
        connectedGateways,
      };
    },
    switchLayoutDragLocked(state: OverviewState) {
      return {
        ...state,
        layoutDragLocked: !state.layoutDragLocked,
      };
    },
  },
});
