import { FC } from "react";
import { VehicleListTable } from "./Table";
import { VehicleListGrid } from "./Grid";
import { Vehicle } from "../../../../features/vehicle/domain/type/Vehicle";
import { useAppResponsiveScreen } from "../../../../app/hook/Breakpoint";
import { AppEmptyContentStateComponent } from "../../../../core/presentation/component/State";

export type VehicleListDataProps = {
  data?: Vehicle[] | null;
  onItemClicked: (item: Vehicle) => void;
};
export const VehicleListData: FC<VehicleListDataProps> = (props) => {
  const { isMobileScreen } = useAppResponsiveScreen();

  return props.data && props.data.length > 0 ? (
    isMobileScreen ? (
      <VehicleListGrid {...props} />
    ) : (
      <VehicleListTable {...props} />
    )
  ) : (
    <AppEmptyContentStateComponent
      title={"Sin vehículos"}
      description={"No hay vehículos registrados..."}
    />
  );
};
