import AcmeIcon from "../../../assets/image/AICON.png";
import { useState, useEffect, DOMAttributes } from "react";
import { isEmpty } from "lodash";
import { Avatar } from "antd";
import { useAppCustomer } from "../../../app/hook/Customer";

export const CustomerLogo: React.FC = () => {
  const { customer } = useAppCustomer();
  const [error, setError] = useState(false);

  const handleImageError: DOMAttributes<HTMLImageElement>["onError"] = () => {
    setError(true);
  };

  useEffect(() => {
    if (customer?.id) {
      setError(false);
    } else {
      setError(true);
    }
  }, [customer]);

  return error ? (
    <Avatar
      size={64}
      icon={<img src={AcmeIcon} alt={"ACME"} />}
      style={{ background: "transparent" }}
    />
  ) : (
    <img
      className="w-full h-full"
      src={`https://iot-admin.acmecia.com/customers/${customer?.id}.png`}
      alt={"Cliente"}
      onError={handleImageError}
    />
  );
};
