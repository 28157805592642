import { Button, Divider, Layout } from "antd";
import {
  FC,
  useState,
  PropsWithChildren,
  useCallback,
  useRef,
  useEffect,
  UIEventHandler,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { DashboardLayoutFooter } from "./DashboardFooter";
import { DashboardLayoutMainMenu } from "./Menu";
import { DashboardLayoutHeaderLarge } from "./HeaderLarge";

type Props = {
  onActionClick: (action: string) => void;
  collapsed: boolean;
};

const { Sider } = Layout;
export const DashboardLargeLayout: FC<PropsWithChildren<Props>> = ({
  children,
  onActionClick,
  collapsed,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerElevated, setHeaderElevated] = useState(false);
  const [topReached, setTopReached] = useState(false);
  const [bottomReached, setBottomReached] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const scrollCallback = useCallback<UIEventHandler<HTMLDivElement>>(
    (e) => {
      const target = e?.currentTarget || contentRef.current;
      const totalHeight = window.innerHeight;
      const headerHeight = headerRef.current?.clientHeight || 0;
      const contentHeight = target.clientHeight;
      const scrollHeight = target.scrollHeight;
      const scrollOffset = target.scrollTop;

      if (scrollHeight <= totalHeight - headerHeight + 50) {
        if (headerElevated) setHeaderElevated(false);
        if (!topReached) setTopReached(true);
        if (!bottomReached) setBottomReached(true);
      } else {
        if (scrollOffset > 5) {
          setTopReached(false);
          setHeaderElevated(true);
        } else {
          setTopReached(true);
          setHeaderElevated(false);
        }
        setBottomReached(scrollOffset + contentHeight >= scrollHeight - 5);
      }
    },
    [setHeaderElevated, setTopReached, setBottomReached, headerRef, contentRef]
  );

  useEffect(() => {
    setTimeout(scrollCallback, 500);
  }, [location, scrollCallback]);

  return (
    <Layout
      className={
        "w-screen h-screen overflow-x-hidden overflow-y-auto relative bg-sider"
      }
    >
      <Layout className={"h-full w-full overflow-hidden bg-transparent"}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(_) => onActionClick("menu")}
          width={200}
          collapsedWidth={80}
          trigger={
            <Button
              shape="circle"
              size="large"
              type="primary"
              className={`transition-all`}
              icon={
                <FontAwesomeIcon icon={collapsed ? faChevronRight : faClose} />
              }
            />
          }
        >
          <div className="pl-2 pr-0.5 overflow-y-auto pt-8 z-30">
            <DashboardLayoutMainMenu />
          </div>
        </Sider>
        <div
          className={`overflow-hidden transition-all w-full h-full bg-white dark:bg-neutral-800 relative ${
            topReached ? "rounded-tl-3xl" : "rounded-tl-none"
          } ${bottomReached ? "rounded-bl-3xl" : "rounded-bl-none"}`}
        >
          <div
            className={
              "w-full overflow-x-hidden overflow-y-auto h-full flex flex-col"
            }
            ref={contentRef}
            onScroll={scrollCallback}
          >
            <header
              className={`w-full transition-all z-50 sticky top-0 bg-white dark:bg-neutral-800 ${
                headerElevated ? "shadow" : "shadow-none"
              }`}
              ref={headerRef}
            >
              <div
                className={`w-full transition-all bg-white dark:bg-neutral-800`}
              >
                <DashboardLayoutHeaderLarge onActionClick={onActionClick} />
              </div>
            </header>
            <div className="flex-1 h-fit">{children}</div>
            <div className="pt-12">
              <Divider />
              <footer>
                <DashboardLayoutFooter />
              </footer>
            </div>
          </div>
        </div>
      </Layout>
    </Layout>
  );
};
