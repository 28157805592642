import { Layout } from "react-grid-layout";
import { DashboardAllowedRoutes } from "../../../../core/layout/dashboard/domain/type/AllowedRoutes";
const kMIN_COL_WIDTH = 50;
const kMIN_CARD_WIDTH = 350;
export class OverviewLayoutUseCase {
  constructor() {}
  static generateLayout(allowedRoutes: DashboardAllowedRoutes) {
    const smLayout: Layout[] = [];

    const mdLayout: Layout[] = [];

    const lgLayout: Layout[] = [];

    if (allowedRoutes.connected_users) {
      smLayout.push({
        i: "connected_users",
        x: 0,
        y: 0,
        w: 4,
        h: 4,
        minH: 4,
        minW: 4,
      });
      mdLayout.push({
        i: "connected_users",
        x: 0,
        y: 0,
        w: 4,
        h: 4,
        minH: 4,
        minW: 4,
      });
      lgLayout.push({
        i: "connected_users",
        x: 0,
        y: 0,
        w: 4,
        h: 4,
        minH: 4,
        minW: 4,
      });
    }

    if (allowedRoutes.connected_gateways) {
      smLayout.push({
        i: "connected_gateways",
        x: 0,
        y: 4,
        w: 4,
        h: 4,
        minH: 4,
        minW: 4,
      });
      mdLayout.push({
        i: "connected_gateways",
        x: 4,
        y: 0,
        w: 4,
        h: 4,
        minH: 4,
        minW: 4,
      });
      lgLayout.push({
        i: "connected_gateways",
        x: 0,
        y: 4,
        w: 4,
        h: 4,
        minH: 4,
        minW: 4,
      });
    }
    if (allowedRoutes.vehicle_list) {
      smLayout.push({
        i: "vehicles_map",
        x: 0,
        y: 8,
        w: 4,
        h: 4,
        minH: 4,
        minW: 4,
      });
      mdLayout.push({
        i: "vehicles_map",
        x: 0,
        y: 4,
        w: 8,
        h: 6,
        minH: 6,
        minW: 8,
      });
      lgLayout.push({
        i: "vehicles_map",
        x: 4,
        y: 0,
        w: 8,
        h: 8,
        minH: 8,
        minW: 8,
      });
    }
    if (allowedRoutes.billing_account) {
      smLayout.push({
        i: "billing_account",
        x: 0,
        y: 12,
        w: 4,
        h: 4,
        minH: 4,
        minW: 4,
      });
      mdLayout.push({
        i: "billing_account",
        x: 0,
        y: 8,
        w: 8,
        h: 6,
        minH: 6,
        minW: 8,
      });
      lgLayout.push({
        i: "billing_account",
        x: 0,
        y: 8,
        w: 4,
        h: 8,
        minH: 8,
        minW: 4,
      });
    }

    return {
      smLayout,
      mdLayout,
      lgLayout,
    };
  }
}
