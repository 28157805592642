import { useState } from "react";
import { AuthRepository } from "../../auth/data/repository/Auth";
import { AppTaskState } from "../../../app/domain/type/State";
import { PasswordRecoveryRequestData } from "../../auth/domain/type/Login";
import { AppTaskStateG } from "../../../app/domain/model/State";

export function useRecoveryRequestViewModel() {
  const [requestState, setRequestState] =
    useState<AppTaskState<boolean> | null>(null);
  async function sendRequest(data: PasswordRecoveryRequestData) {
    setRequestState(AppTaskStateG.loading());
    try {
      await AuthRepository.sendPasswordRecoveryRequest(data);
      setRequestState(AppTaskStateG.success(true));
      return true;
    } catch (e: any) {
      setRequestState(AppTaskStateG.error(e));
    }
  }

  function onRequestStateReceived() {
    setRequestState(null);
  }

  return {
    requestState,
    sendRequest,
    onRequestStateReceived,
  };
}
