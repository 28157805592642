import { FC, MouseEventHandler, useCallback, useEffect } from "react";
import { Button, notification, Tabs, Timeline } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faTerminal } from "@fortawesome/free-solid-svg-icons";
import { GatewayActionConfirmationModal } from "./ConfirmationModal";
import { Gateway } from "../../../../domain/type/Gateway";
import { useGatewayActionViewModel } from "../../../GatewayActionViewModel";
import { GatewayActionResponseState } from "../../../../domain/type/GatewayActions";
import { SocketIOService } from "../../../../../../app/service/SocketIO";
import { ResponsiveContainer } from "../../../../../../core/presentation/component/Container";
import { AppLoader } from "../../../../../../core/presentation/component/AppLoader";
import { AppEmptyContentStateComponent } from "../../../../../../core/presentation/component/State";

type Props = {
  gateway: Gateway;
};

export const GatewayActionsTab: FC<Props> = ({ gateway }) => {
  const {
    gatewayActionList,
    onFetchListStateReceived,
    fetchListState,
    fetchList,
    selectAction,
    onActionSelectCompleted,
    requestAction,
    toRequestAction,
    onRequestActionStateReceived,
    requestActionState,
    actionExecutionFailed,
    actionExecutionState,
    actionExecutionSuccess,
    onActionExecutionStateReceived,
    startExecutionState,
  } = useGatewayActionViewModel();

  const fetchGatewayDeviceList = useCallback(() => {
    void fetchList(gateway.id);
  }, [gateway]);

  useEffect(() => {
    void fetchGatewayDeviceList();
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Ocurrió un error al obtener los dispositivos.",
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!requestActionState && !requestActionState.loading) {
      if (requestActionState.hasError) {
        notification.error({
          description: requestActionState.error?.message,
          message: "Solicitud de comando",
        });
      } else {
        startExecutionState();
      }
      onRequestActionStateReceived();
    }
  }, [requestActionState]);

  useEffect(() => {
    if (!!actionExecutionState && !actionExecutionState.loading) {
      if (actionExecutionState.hasError) {
        notification.error({
          description: actionExecutionState.error?.message,
          message: "Ejecución de comando",
        });
      } else {
        notification.success({
          message: "OK",
        });
      }
      onActionExecutionStateReceived();
    }
  }, [actionExecutionState]);

  const onButtonClicked: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      const deviceIndex = Number(event.currentTarget.dataset.device_index);
      const actionIndex = Number(event.currentTarget.dataset.action_index);
      const action = gatewayActionList
        ?.at(deviceIndex)
        ?.actions?.at(actionIndex);
      if (!!action) {
        selectAction(action);
      } else {
        onActionSelectCompleted();
        notification.info({
          message: "No se ha encontrado la acción seleccionada.",
        });
      }
    },
    [selectAction, onActionSelectCompleted, gatewayActionList]
  );

  const actionsListener = useCallback((data: GatewayActionResponseState) => {
    Object.keys(data).forEach((key1) => {
      if (key1 !== "gateway_key") {
        const deviceKey = key1;
        const deviceExecution = data[deviceKey];
        Object.keys(deviceExecution).forEach((key2) => {
          const actionKey = key2;
          const deviceAction = deviceExecution[actionKey];
          if (deviceAction.success) {
            actionExecutionSuccess();
          } else {
            actionExecutionFailed(deviceAction.message);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    SocketIOService.socketOn("actions-response", actionsListener);
    return () => {
      SocketIOService.socketOff("actions-response", actionsListener);
    };
  }, []);

  return (
    <ResponsiveContainer>
      <GatewayActionConfirmationModal
        action={toRequestAction}
        onCancel={onActionSelectCompleted}
        onSubmit={requestAction}
        open={!!toRequestAction}
      />

      <AppLoader
        loading={
          (!!fetchListState && fetchListState.loading) ||
          (!!requestActionState && requestActionState.loading) ||
          (!!actionExecutionState && actionExecutionState.loading)
        }
      />
      <Button.Group className={"block my-2"}>
        <Button
          type={"default"}
          onClick={fetchGatewayDeviceList}
          icon={<FontAwesomeIcon icon={faRefresh} />}
        >
          Actualizar
        </Button>
      </Button.Group>

      <div className={"overflow-visible"}>
        {!!gatewayActionList && gatewayActionList.length > 0 ? (
          <Tabs
            items={gatewayActionList.map((it1, index1) => ({
              label: `${it1.device.description} (${it1.device.key})`,
              key: it1.device.key,
              children: (
                <Timeline>
                  {it1.actions.map((it2, index2) => (
                    <Timeline.Item key={`action-${it1.device.key}-${it2.path}`}>
                      <Button
                        className="bg-black text-white"
                        data-device_index={index1}
                        data-action_index={index2}
                        onClick={onButtonClicked}
                        icon={<FontAwesomeIcon icon={faTerminal} />}
                        shape="default"
                      >
                        {it2.name}
                      </Button>
                    </Timeline.Item>
                  ))}
                </Timeline>
              ),
            }))}
          />
        ) : (
          <AppEmptyContentStateComponent description={"No hay acciones."} />
        )}
      </div>
    </ResponsiveContainer>
  );
};
