import ReactDOM from "react-dom/client";
import "./index.css";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "react-medium-image-zoom/dist/styles.css";
import App from "./app/presentation/App";
import reportWebVitals from "./reportWebVitals";
import React from "react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

reportWebVitals();
