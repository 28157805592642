import { FC, useCallback, useEffect, useState } from "react";
import { useOverviewViewModel } from "../ViewModel";
import { Avatar, Button, Card, Typography, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faSignal, faUser } from "@fortawesome/free-solid-svg-icons";

export const ConnectedUsers: FC = () => {
  const [syncDate, setSyncDate] = useState<Date | undefined>();
  const {
    fetchConnectedUsers,
    fetchConnectedUsersState,
    onFetchConnectedUsersStateReceived,
    connectedUsers,
  } = useOverviewViewModel();

  useEffect(() => {
    void fetchConnectedUsers();
  }, []);

  useEffect(() => {
    if (!!fetchConnectedUsersState && !fetchConnectedUsersState.loading) {
      if (fetchConnectedUsersState.hasError) {
        notification.error({
          message: "Error",
          description: fetchConnectedUsersState.error?.message,
        });
        setSyncDate(undefined);
      } else {
        setSyncDate(new Date());
      }
      onFetchConnectedUsersStateReceived();
    }
  }, [fetchConnectedUsersState]);

  return (
    <Card
      hoverable
      loading={!!fetchConnectedUsersState && fetchConnectedUsersState.loading}
      size="small"
      bordered={false}
      className="w-full h-full overflow-hidden"
    >
      <div className="flex flex-col gap-1 relative">
        <Button
          icon={<FontAwesomeIcon icon={faRefresh} />}
          onClick={fetchConnectedUsers}
          className="absolute top-0 right-0"
          size="small"
          type="default"
        >
          Actualizar
        </Button>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col">
            <Typography.Text
              style={{ padding: 0, margin: 0 }}
              className="leading-tight"
            >
              Usuarios
            </Typography.Text>
            <Typography.Text
              style={{ padding: 0, margin: 0 }}
              type="secondary"
              className="leading-tight"
            >
              {connectedUsers.length + " "} en línea
            </Typography.Text>
          </div>
        </div>
        <div className="w-full overflow-hidden rounded-lg divide-y divide-x-0 flex flex-col gap-2">
          {connectedUsers.map((it) => {
            return (
              <div
                key={`c-user-${it.id}`}
                className="w-full flex flex-row gap-2 max-w-full p-1"
              >
                <Avatar size={"small"}>
                  <FontAwesomeIcon icon={faUser} size="sm" />
                </Avatar>
                <div className="flex flex-col">
                  <Typography.Text
                    className="w-full overflow-hidden leading-tight"
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {it.name + " " + it.lastname}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                    className="w-full overflow-hidden leading-tight"
                    type="secondary"
                  >
                    {it.corporateEmail}
                  </Typography.Text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};
