import { ConfigProvider } from "antd";
import { useAppTheme } from "../../hook/Theme";
import esES from "antd/locale/es_ES";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AppRoutes } from "../../domain/implementation/AppRoutes";

export function AppComponent() {
  const { themes } = useAppTheme();

  return (
    <ConfigProvider locale={esES} theme={themes.primaryTheme}>
      <RouterProvider router={createBrowserRouter(AppRoutes)} />
    </ConfigProvider>
  );
}
