import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import { Customer } from "../../domain/type/Customer";
import { ApiService } from "../../../../app/service/RemoteClient";

export class RemoteCustomerSource extends BaseRemoteSource {
  static async getCustomer(): Promise<RemoteSourceResponse<Customer>> {
    try {
      const customer = await ApiService.getWithAuth("/customer/get");
      this.checkResponseCredentials(customer);
      return customer.data as RemoteSourceResponse<Customer>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerDomainByEmail(
    email: string
  ): Promise<RemoteSourceResponse<Customer>> {
    try {
      const customerStyle = await ApiService.postWithAuth(
        "/customer/get-style",
        {
          email,
        }
      );
      this.checkResponseCredentials(customerStyle);
      return customerStyle.data as RemoteSourceResponse<Customer>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
