import { FC } from "react";
import { Card, Typography } from "antd";
import { OnlineGateway } from "../../../domain/type/Gateway";
import { ResponsiveContainer } from "../../../../../core/presentation/component/Container";
import { MultipleGatewayMap } from "../../../../../core/presentation/component/GatewayMap";

type Props = {
  gateway: OnlineGateway;
};

export const GatewayLocationTab: FC<Props> = ({ gateway }) => {
  return (
    <ResponsiveContainer>
      <Card
        bodyStyle={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
        style={{
          overflow: "hidden",
        }}
        bordered={false}
        hoverable
      >
        <div className="w-full h-screen">
          <MultipleGatewayMap gatewayList={[gateway]} />
        </div>
      </Card>
    </ResponsiveContainer>
  );
};
