import { FC, MouseEventHandler, useCallback } from "react";
import { Button, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun, faUser } from "@fortawesome/free-solid-svg-icons";
import { useAppTheme } from "../../../../../app/hook/Theme";
import { CustomerLogo } from "../../../../presentation/component/CustomerLogo";
import { useDashboardHeader } from "../hook/Header";
import { DashboardTimeLabel } from "./TimeLabel";
import { useAppCustomer } from "../../../../../app/hook/Customer";

type Props = {
  onActionClick: (action: string) => void;
};

export const DashboardLayoutHeaderLarge: FC<Props> = ({ onActionClick }) => {
  const { isDarkThemeEnabled, switchDarkTheme } = useAppTheme();
  const { customer } = useAppCustomer();
  const { title } = useDashboardHeader();

  const onClick = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      onActionClick(e.currentTarget.dataset["key"] as string);
    },
    [onActionClick]
  );

  return (
    <div
      className={
        "w-full flex flex-row gap-8 place-items-center px-12 py-3 relative overflow-hidden"
      }
    >
      <div className="h-10 overflow-hidden z-30 my-auto flex flex-col items-center">
        {isDarkThemeEnabled ? (
          <span className="text-white my-auto font-quicksand text-lg font-bold">
            {customer?.businessName}
          </span>
        ) : (
          <CustomerLogo />
        )}
      </div>

      <div className={"flex flex-col z-30"}>
        {title && (
          <Typography.Text
            className="leading-tight"
            style={{
              padding: 0,
              margin: 0,
            }}
            type="secondary"
            strong
          >
            {title}
          </Typography.Text>
        )}
        <DashboardTimeLabel />
      </div>
      <div className="ml-auto place-content-center flex flex-row place-items-center gap-2 z-30">
        <Button
          shape={"circle"}
          type={"text"}
          icon={<FontAwesomeIcon icon={faUser} />}
          data-key={"user"}
          onClick={onClick}
        />
        <Button
          shape="circle"
          type={"text"}
          icon={<FontAwesomeIcon icon={isDarkThemeEnabled ? faSun : faMoon} />}
          onClick={() => {
            switchDarkTheme();
          }}
        />
      </div>
    </div>
  );
};
