import { FC, useEffect, useState } from "react";
import { useDashboardHeader } from "../../../../core/layout/dashboard/presentation/hook/Header";
import { useOverviewViewModel } from "../ViewModel";
import { Avatar, Button, Card, Spin, Typography, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faTruck } from "@fortawesome/free-solid-svg-icons";

export const ConnectedGateways: FC = () => {
  const [syncDate, setSyncDate] = useState<Date | undefined>();
  const {
    fetchConnectedGateways,
    fetchConnectedGatewaysState,
    onFetchConnectedGatewaysStateReceived,
    connectedGateways,
  } = useOverviewViewModel();

  useEffect(() => {
    void fetchConnectedGateways();
  }, []);

  useEffect(() => {
    if (!!fetchConnectedGatewaysState && !fetchConnectedGatewaysState.loading) {
      if (fetchConnectedGatewaysState.hasError) {
        notification.error({
          message: "Error",
          description: fetchConnectedGatewaysState.error?.message,
        });
        setSyncDate(undefined);
      } else {
        setSyncDate(new Date());
      }
      onFetchConnectedGatewaysStateReceived();
    }
  }, [fetchConnectedGatewaysState]);

  return (
    <Card
      hoverable
      loading={
        !!fetchConnectedGatewaysState && fetchConnectedGatewaysState.loading
      }
      size="small"
      bordered={false}
      className="w-full h-full"
    >
      <div className="flex flex-col gap-2 relative">
        <Button
          icon={<FontAwesomeIcon icon={faRefresh} />}
          onClick={fetchConnectedGateways}
          className="absolute top-0 right-0"
          size="small"
          type="default"
        >
          Actualizar
        </Button>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col">
            <Typography.Text
              style={{ padding: 0, margin: 0 }}
              className="leading-tight"
            >
              Vehículos
            </Typography.Text>
            <Typography.Text
              style={{ padding: 0, margin: 0 }}
              type="secondary"
              className="leading-tight"
            >
              {connectedGateways.length + " "} en línea
            </Typography.Text>
          </div>
        </div>
        <div className="w-full overflow-hidden rounded-lg flex flex-col gap-2">
          {connectedGateways.map((it) => {
            return (
              <div
                key={`c-user-${it.id}`}
                className="w-full flex flex-row gap-2 max-w-full p-1"
              >
                <Avatar size={"small"}>
                  <FontAwesomeIcon icon={faTruck} />
                </Avatar>
                <div className="flex flex-col">
                  <Typography.Text
                    className="w-full overflow-hidden leading-none"
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {it.Vehicle?.name}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                    className="w-full overflow-hidden leading-tight"
                    type="secondary"
                  >
                    {it.Vehicle?.plate}
                  </Typography.Text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};
