import { useState, useEffect } from "react";
import LogoAcme from "../../../assets/image/Logo-ACME.png";
import LogoAcmeWhite from "../../../assets/image/Logo-ACME-white.png";
import { useAppCustomer } from "../../../app/hook/Customer";
import { useAppTheme } from "../../../app/hook/Theme";

export const CustomerHeaderLogo: React.FC = () => {
  const { customer } = useAppCustomer();
  const [error, setError] = useState(false);
  const { isDarkThemeEnabled } = useAppTheme();
  const handleImageError = () => {
    setError(true);
  };

  useEffect(() => {
    if (customer?.id) {
      setError(false);
    } else {
      setError(true);
    }
  }, [customer]);

  return (
    <>
      {error ? (
        <img
          alt={"icon"}
          src={isDarkThemeEnabled ? LogoAcmeWhite : LogoAcme}
          className="object-contain w-full text-center h-full overflow-hidden"
        />
      ) : (
        <img
          className="object-contain w-full text-center h-full overflow-hidden filter dark:brightness-0 dark:invert"
          src={`https://iot-admin.acmecia.com/customers/${customer?.id}_logo.png`}
          alt={"Cliente"}
          onError={handleImageError}
        />
      )}
    </>
  );
};
