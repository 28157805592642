import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { Vehicle } from "../../domain/type/Vehicle";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import { ApiService } from "../../../../app/service/RemoteClient";

export class RemoteVehicleSource extends BaseRemoteSource {
  static async getVehicle(id: number): Promise<RemoteSourceResponse<Vehicle>> {
    try {
      const vehicle = await ApiService.postWithAuth("/vehicle/get", { id });
      this.checkResponseCredentials(vehicle);
      return vehicle.data as RemoteSourceResponse<Vehicle>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getAllVehicles(): Promise<RemoteSourceResponse<Vehicle[]>> {
    try {
      const vehicleList = await ApiService.getWithAuth("/vehicle/list");
      this.checkResponseCredentials(vehicleList);
      return vehicleList.data as RemoteSourceResponse<Vehicle[]>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
