import { FC, HTMLProps, MutableRefObject, PropsWithChildren } from "react";
import { Layout } from "antd";

export type ResponsiveContainerProps = PropsWithChildren<{
  className?: HTMLProps<HTMLDivElement>["className"];
  style?: HTMLProps<HTMLDivElement>["style"];
  divRef?: MutableRefObject<HTMLDivElement> | ((div: HTMLDivElement) => void);
}>;
export const ResponsiveContainer: FC<ResponsiveContainerProps> = ({
  style,
  className,
  children,
  divRef,
}) => {
  return (
    <Layout.Content
      className={`relative mx-auto sm:max-w-md md:max-w-3xl  lg:max-w-4xl xl:max-w-5xl w-full h-fit pt:2 md:pt-4 px-2 md:px-4 ${className}`}
      style={style}
      ref={divRef}
    >
      {children}
    </Layout.Content>
  );
};
