import { AppTaskState } from "../type/State";

export class AppTaskStateG<T> {
  static loading<T>(): AppTaskState<T> {
    return {
      loading: true,
      isSuccess: false,
      hasError: false,
    };
  }

  static success<T>(payload: T): AppTaskState<T> {
    return {
      loading: false,
      isSuccess: true,
      hasError: false,
      data: payload,
    };
  }

  static error<T>(error: Error): AppTaskState<T> {
    return {
      loading: false,
      isSuccess: false,
      hasError: true,
      error,
    };
  }
}
