export type AppRegularColorPalette = {
  colorPrimary: string;
  colorSecondary: string;
  colorTertiary: string;
  colorQuaternary: string;
};

export type AppColorPalette = AppRegularColorPalette & {
  textColorOnPrimary: string;
  textColorSecondary: string;
  textColorHeader: string;
  textColorSubtitle: string;
  textColorBody: string;
  textColorOnSecondary: string;
  textColorOnTertiary: string;
  textColorOnQuaternary: string;
  headerColor: string;
  headerTextColor: string;
  backgroundColor: string;
  textColorOnBackground: string;
  paperColor: string;
  textColorOnPaper: string;
  paperColorSecondary: string;
  textColorOnPaperSecondary: string;
};

export const kDEFAULT_REGULAR_COLOR_PALETTE: AppRegularColorPalette = {
  colorPrimary: "#f8aa19",
  colorSecondary: "#1a2a53",
  colorTertiary: "#f18c27",
  colorQuaternary: "#f18c27",
};
