import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useSearchParams, NavLink as RouterLink } from "react-router-dom";
import { Typography, notification } from "antd";
import { usePasswordResetViewModel } from "./ViewModel";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { CustomerLogo } from "../../../core/presentation/component/CustomerLogo";
import {
  PasswordRecoveryForm,
  PasswordRecoveryFormData,
} from "./component/Form";

export const PasswordUpdatePage: FC = () => {
  const [params] = useSearchParams();
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const [passwordUpdated, setPasswordUpdated] = useState<boolean | null>(null);
  const {
    onProceedChangeStateReceived,
    onValidationStateReceived,
    proceedChangeState,
    proceedPasswordReset,
    validateToken,
    validationState,
  } = usePasswordResetViewModel();

  useEffect(() => {
    if (!!validationState && !validationState.loading) {
      if (validationState.hasError) {
        notification.error({
          message: "Verificación",
          description: validationState.error?.message,
        });
      } else {
        setIsTokenValid(true);
      }
      onValidationStateReceived();
    }
  }, [validationState]);

  useEffect(() => {
    if (!!proceedChangeState && !proceedChangeState.loading) {
      if (proceedChangeState.hasError) {
        notification.error({
          message: "Actualizar Contraseña",
          description: proceedChangeState.error?.message,
        });
        setPasswordUpdated(false);
      } else {
        notification.success({
          message: "Actualizar Contraseña",
          description: "Su contraseña ha sio actualizada.",
        });
        setPasswordUpdated(true);
      }
      onProceedChangeStateReceived();
    }
  }, [proceedChangeState]);

  useEffect(() => {
    if (validationState === null) {
      void validateToken({
        token: params.get("tt") as string,
        userId: Number.parseInt(params.get("userId") as string),
      });
    }
  }, []);

  const updatePassword = useCallback(async (data: PasswordRecoveryFormData) => {
    proceedPasswordReset({
      token: params.get("tt") as string,
      userId: Number.parseInt(params.get("userId") as string),
      password: data.password,
      passwordRepeat: data.passwordConfirmation,
    });
    return true;
  }, []);

  return (
    <div className="flex flex-col items-start md:max-w-sm lg:max-w-md px-6 py-10 h-fit w-full bg-white shadow-lg dark:bg-neutral-700 gap-4">
      <AppLoader
        loading={
          (!!validationState && validationState.loading) ||
          (!!proceedChangeState && proceedChangeState.loading)
        }
      />
      <div className="max-w-xs overflow-hidden">
        <CustomerLogo />
      </div>
      {isTokenValid ? (
        passwordUpdated === null ? (
          <Fragment>
            <PasswordRecoveryForm onSubmit={updatePassword} />
          </Fragment>
        ) : passwordUpdated ? (
          <Fragment>
            <span className="text-neutral-800 dark:text-neutral-200 text-lg uppercase font-medium">
              Contraseña Actualizada
            </span>
            <Typography.Text>
              Su contraseña ha sido actualizada, ya puede iniciar sesión.
            </Typography.Text>
            <Typography.Text type={"success"}>
              <FontAwesomeIcon icon={faCheck} className={"text-4xl"} />
            </Typography.Text>
            <RouterLink to={"/auth"}>
              <Typography.Text className="text-primary no-underline">
                Iniciar Sesión
              </Typography.Text>
            </RouterLink>
          </Fragment>
        ) : (
          <Fragment>
            <Typography.Title level={3}>Ha ocurrido un error</Typography.Title>
            <Typography.Text>
              Ha ocurrido un error o el url ingresado no es válido. Por favor,
              realice la solicitud nuevamente.
            </Typography.Text>
          </Fragment>
        )
      ) : (
        <Fragment>
          <Typography.Title level={3}>Url no válido</Typography.Title>
          <Typography.Text>
            El url no está disponible, venció o no existe. Por favor, vuelva a
            realizar la solicitud.
          </Typography.Text>
        </Fragment>
      )}
    </div>
  );
};
