import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppCustomer } from "../../../app/hook/Customer";
import { useLoginViewModel } from "./ViewModel";
import { notification } from "antd";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { CustomerLogo } from "../../../core/presentation/component/CustomerLogo";
import { FindCustomerByEmailForm } from "./component/FindCustomer";
import { CodeVerificationForm } from "./component/VerificationForm";
import { LoginForm } from "./component/LoginForm";

type Props = {};

export const AuthPage: FC<Props> = ({}) => {
  const navigate = useNavigate();
  const { setCustomer, customer } = useAppCustomer();
  const {
    login,
    loginState,
    postLoginState,
    onLoginStateReceived,
    onPostLoginStateReceived,
    verificateCode,
    resendCode,
    resendCodeState,
    onResendCodeStateReceived,
    fetchCustomerByEmail,
    fetchCustomerByEmailState,
    onFetchCustomerByEmailStateReceived,
    loginEmail,
    resetLoginEmail,
  } = useLoginViewModel();
  const [postLoginEnabled, setPostLoginEnabled] = useState<boolean>();

  useEffect(() => {
    if (!!loginState && !loginState.loading) {
      if (loginState.hasError) {
        notification.error({
          message: "Inicio de Sesión",
          description: loginState.error?.message,
        });
      } else {
        setPostLoginEnabled(true);
      }
      onLoginStateReceived();
    }
  }, [loginState]);

  useEffect(() => {
    if (!!fetchCustomerByEmailState && !fetchCustomerByEmailState.loading) {
      if (fetchCustomerByEmailState.hasError) {
        notification.error({
          message: "Inicio de Sesión",
          description: fetchCustomerByEmailState.error?.message,
        });
      } else {
        setCustomer(fetchCustomerByEmailState.data!!);
      }
      onFetchCustomerByEmailStateReceived();
    }
  }, [fetchCustomerByEmailState]);

  useEffect(() => {
    if (!!postLoginState && !postLoginState.loading) {
      if (postLoginState.hasError) {
        notification.error({
          message: "Verificación",
          description: postLoginState.error?.message,
        });
      } else {
        navigate("/dashboard/overview");
      }
      onPostLoginStateReceived();
    }
  }, [postLoginState]);

  useEffect(() => {
    if (!!resendCodeState && !resendCodeState.loading) {
      if (resendCodeState.hasError) {
        notification.error({
          message: "Reenviar código de verificación",
          description: resendCodeState.error?.message,
        });
      } else {
        notification.success({
          message: "Código Reenviado",
          description: "Se ha enviado un nuevo código a su correo.",
        });
      }
      onResendCodeStateReceived();
    }
  }, [resendCodeState]);

  return (
    <div className="flex flex-col items-start md:max-w-sm lg:max-w-md px-6 py-10 h-fit w-full bg-white shadow-lg dark:bg-neutral-700 gap-4">
      <AppLoader
        loading={
          (!!loginState && loginState.loading) ||
          (!!postLoginState && postLoginState.loading) ||
          (!!resendCodeState && resendCodeState.loading) ||
          (!!fetchCustomerByEmailState && fetchCustomerByEmailState.loading)
        }
      />
      <div className="max-w-xs overflow-hidden">
        <CustomerLogo />
      </div>
      {!customer && !loginEmail ? (
        <FindCustomerByEmailForm onSubmit={fetchCustomerByEmail} />
      ) : postLoginEnabled ? (
        <CodeVerificationForm
          onBack={() => {
            setPostLoginEnabled(false);
          }}
          onSubmit={verificateCode}
          onResend={resendCode}
        />
      ) : (
        <LoginForm
          initialData={{
            email: loginEmail!!,
          }}
          onSubmit={login}
          onBack={() => {
            resetLoginEmail();
            setPostLoginEnabled(false);
            setCustomer(null!);
          }}
        />
      )}
    </div>
  );
};
