import { FC } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { VehicleListDataProps } from "./Data";
import { Vehicle } from "../../../../features/vehicle/domain/type/Vehicle";

export const VehicleListTable: FC<VehicleListDataProps> = ({
  data,
  onItemClicked,
}) => {
  const columns: ColumnsType<Vehicle> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Placa",
      dataIndex: "plate",
      key: "plate",
    },
  ];
  return (
    <Table
      onRow={(record, _) => {
        return {
          onClick: () => onItemClicked(record),
        };
      }}
      size={"small"}
      columns={columns}
      dataSource={data!!}
      rowKey={"id"}
    />
  );
};
