import { Drawer, Layout } from "antd";
import { FC, useState, PropsWithChildren } from "react";
import { DashboardLayoutFooter } from "./DashboardFooter";
import { DashboardLayoutMainMenu } from "./Menu";
import { DashboardLayoutHeaderSmall } from "./HeaderSmall";

const { Content } = Layout;

type Props = {
  onActionClick: (key: string) => void;
  collapsed: boolean;
};

export const DashboardMobileLayout: FC<PropsWithChildren<Props>> = ({
  children,
  onActionClick,
  collapsed,
}) => {
  return (
    <Layout className={"w-screen h-screen overflow-x-hidden overflow-y-auto"}>
      <Drawer
        bodyStyle={{
          padding: 0,
          margin: 0,
        }}
        placement="left"
        onClose={() => {
          onActionClick("menu");
        }}
        open={collapsed}
      >
        <DashboardLayoutMainMenu />
      </Drawer>
      <Layout
        className={
          "h-screen w-screen overflow-x-hidden overflow-y-auto bg-neutral-100 dark:bg-neutral-800"
        }
      >
        <div className="flex flex-row w-full">
          <div className="rounded-2xl w-full overflow-hidden">
            <DashboardLayoutHeaderSmall onActionClick={onActionClick} />
          </div>
        </div>
        <Content className={"w-full h-fit"}>
          {children}
          <DashboardLayoutFooter />
        </Content>
      </Layout>
    </Layout>
  );
};
