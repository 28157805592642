import { useState } from "react";
import { AppTaskState } from "../../../app/domain/type/State";
import { AppTaskStateG } from "../../../app/domain/model/State";
import { DashboardRepository } from "../../../core/layout/dashboard/data/repository/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../app/domain/model/Store";

export function useOverviewViewModel() {
  const [fetchConnectedUsersState, setFetchConnectedUsersState] =
    useState<AppTaskState<boolean> | null>(null);
  const [fetchConnectedGatewaysState, setFetchConnectedGatewaysState] =
    useState<AppTaskState<boolean> | null>(null);
  const state = useSelector((state: RootState) => state.overview);
  const dispatch = useDispatch<Dispatch>();

  async function fetchConnectedUsers() {
    if (fetchConnectedUsersState?.loading) return;
    setFetchConnectedUsersState(AppTaskStateG.loading());
    try {
      const data = await DashboardRepository.getConnectedUsers();
      if (data.ok) {
        setFetchConnectedUsersState(AppTaskStateG.success(true));
        dispatch.overview.updateConnectedUsers(data.data!!);
      } else {
        setFetchConnectedUsersState(
          AppTaskStateG.error(new Error(data.message!!))
        );
      }
    } catch (error: any) {
      setFetchConnectedUsersState(AppTaskStateG.error(error));
    }
  }

  function onFetchConnectedUsersStateReceived() {
    setFetchConnectedUsersState(null);
  }

  async function fetchConnectedGateways() {
    if (fetchConnectedGatewaysState?.loading) return;
    setFetchConnectedGatewaysState(AppTaskStateG.loading());
    try {
      const data = await DashboardRepository.getConnectedGateways();
      if (data.ok) {
        setFetchConnectedGatewaysState(AppTaskStateG.success(true));
        dispatch.overview.updateConnectedGateways(data.data!!);
      } else {
        setFetchConnectedGatewaysState(
          AppTaskStateG.error(new Error(data.message!!))
        );
      }
    } catch (error: any) {
      setFetchConnectedGatewaysState(AppTaskStateG.error(error));
    }
  }

  function onFetchConnectedGatewaysStateReceived() {
    setFetchConnectedGatewaysState(null);
  }

  return {
    fetchConnectedUsersState,
    fetchConnectedUsers,
    onFetchConnectedUsersStateReceived,
    connectedUsers: state.connectedUsers,
    fetchConnectedGatewaysState,
    fetchConnectedGateways,
    onFetchConnectedGatewaysStateReceived,
    connectedGateways: state.connectedGateways,
  };
}
