import { FC, useEffect, useMemo, useState } from "react";
import {
  ChartData1,
  ChartGaugeDefinition2,
  ChartSchemaDefinition,
} from "../../../domain/type/Chart";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import { cloneDeep } from "lodash";
import { useAppResponsiveScreen } from "../../../../app/hook/Breakpoint";

type Props = {
  tooltipTitle: string;
  items: Array<Array<ChartData1<number>>>;
  schema?: ChartSchemaDefinition;
  id: string;
};

export const GaugeChart: FC<Props> = ({ tooltipTitle, items, schema, id }) => {
  const [gauges, setGauges] = useState<Array<EChartsOption>>([]);
  const responsiveScreen = useAppResponsiveScreen();
  const baseGauges = useMemo<Array<EChartsOption>>(() => {
    const gauges = schema?.gauge;
    const { isSmallScreen, screenWidth } = responsiveScreen;
    const totalWidth = isSmallScreen ? screenWidth - 15 : 337;
    return (
      (gauges as Array<ChartGaugeDefinition2>)?.map((it) => {
        const series = [];
        const distance = it.distance ?? 0;
        const axisWidth = it.axisWidth ?? 3;
        const axisTickHeight = it.axisTickHeight ?? 10;
        const labelSize = it.labelSize ?? 14;
        const oppositeWidth = it.opposite ? distance + labelSize * 4 : 0;
        const offset =
          oppositeWidth > 0 ? (100 * oppositeWidth) / totalWidth : 0;

        series.push({
          type: "gauge",
          name: it.title,
          radius: `${100 - offset}%`,
          progress: {
            show: it.showProgress,
            width: axisTickHeight + 2,
            distance: 0,
          },
          center: ["50%", "70%"],
          startAngle: it.startAngle ?? 0,
          endAngle: it.endAngle ?? 180,
          min: it.min ?? 0,
          max: it.max ?? 100,
          splitNumber: it.split ?? 10,
          axisLine: {
            lineStyle: {
              color: it.ranges?.map((it2) => [
                it2.tick,
                it2.color ?? "#000",
              ]) ?? [1, "#000"],
              width: axisWidth,
            },
          },
          axisLabel: {
            color: it.labelColor ?? "auto",
            distance: it.opposite
              ? -(labelSize * (it.max ?? 1).toString().length) - 16
              : 8 + (axisWidth + axisTickHeight) / 2,
            fontSize: labelSize,
          },

          axisTick: {
            lineStyle: {
              color: "auto",
              width: it.axisTickWidth ?? 1,
            },
            length: axisTickHeight,
            distance: 0,
          },
          splitLine: {
            lineStyle: {
              color: "auto",
            },
            length: axisTickHeight,
            distance: 0,
          },
          detail: {
            valueAnimation: true,
            formatter: (value: any) => {
              let r = value;
              if (!!value.toFixed) {
                r = value.toFixed(2);
              }
              return ` ${r} ${it.unit ?? ""}`;
            },
            fontSize: (80 - distance) / 4,
            color: it.titleColor ?? "auto",
            fontWeight: "normal",
            zlevel: 50,
          },

          title: {
            color: it.titleColor ?? "auto",
          },
          data: [],
          pointer: {
            itemStyle: {
              color: it.pointerColor ?? "auto",
              zIndex: 50,
            },
            icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
            length: it.pointerSize ?? "80%",
            showAbove: true,
          },
          itemStyle: {
            color: it.progressColor ?? "auto",
          },
        });
        if (it.decoration) {
          series.push({
            type: "gauge",
            radius: distance <= 0 ? "80%" : `${80 - distance}%`,
            center: ["50%", "50%"],
            startAngle: it.startAngle ?? 0,
            endAngle: it.endAngle ?? 180,
            min: it.decoration.min ?? 0,
            max: it.decoration.max ?? 100,
            splitNumber: it.decoration.split ?? 10,
            axisLine: {
              lineStyle: {
                color: it.decoration?.ranges?.map((it2) => [
                  it2.tick,
                  it2.color ?? "#000",
                ]) ?? [1, "#000"],
                width: it.decoration.axisWidth ?? 3,
              },
            },
            axisTick: {
              lineStyle: {
                color: "auto",
              },
              distance: 0,
            },
            axisLabel: {
              color: "auto",
            },
            splitLine: {
              lineStyle: {
                color: "auto",
              },
              distance: 0,
            },
            pointer: {
              show: false,
            },
            detail: {
              show: false,
            },
            title: {
              offsetCenter: [0, "-50%"],
              color: "auto",
            },
            data: [
              {
                name: it.decoration.unit ?? "",
                value: 100,
              },
            ],
          });
        }
        return {
          series,
          totalWidth,
        };
      }) ?? []
    );
  }, [schema, responsiveScreen]);

  useEffect(() => {
    if (!!items && items[0] && !!baseGauges) {
      const copy = cloneDeep(baseGauges);
      items[items.length - 1].forEach((it, idx) => {
        copy[idx].series[0].data = [
          { value: it.value, name: copy[idx].series[0].name },
        ];
      });
      setGauges(copy);
    }
  }, [baseGauges, items]);

  return (
    <div className="w-auto h-fit flex flex-col justify-center items-center">
      {gauges.map((it, idx) => (
        <ReactECharts
          key={`gc-${it}-${idx}`}
          option={it}
          lazyUpdate
          opts={{
            renderer: "canvas",
            height: "auto",
            width: it.totalWidth,
          }}
        />
      ))}
    </div>
  );
};
