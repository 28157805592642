import { FC, PropsWithChildren, createContext, useState } from "react";
import { CustomerUser } from "../../features/customer_user/domain/type/CustomerUser";

type Value = {
  customerUser: CustomerUser | null;
  setCustomerUser: (user: CustomerUser) => void;
};

export const AppUserContext = createContext<Value>({
  customerUser: null,
  setCustomerUser: null!,
});

export const AppUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [customerUser, setCustomerUser] = useState<CustomerUser | null>(null);
  return (
    <AppUserContext.Provider
      value={{
        customerUser,
        setCustomerUser,
      }}
    >
      {children}
    </AppUserContext.Provider>
  );
};
