import { RemoteCustomerSource } from "../source/Remote";

export class CustomerRepository {
  static async getCustomer() {
    return RemoteCustomerSource.getCustomer();
  }

  static async getCustomerStyleByEmail(email: string) {
    return RemoteCustomerSource.getCustomerDomainByEmail(email);
  }
}
