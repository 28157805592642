import { Models } from "@rematch/core";
import { overview } from "../../../features/overview/domain/model/State";

export interface RootModel extends Models<RootModel> {
  overview: typeof overview;
}

export const models: RootModel = {
  overview,
};
