import { FC } from "react";
import { CustomerUser } from "../../../../features/customer_user/domain/type/CustomerUser";
import { UserListTable } from "./Table";
import { UserListGrid } from "./Grid";
import { useAppResponsiveScreen } from "../../../../app/hook/Breakpoint";
import { AppEmptyContentStateComponent } from "../../../../core/presentation/component/State";

export type UserListDataProps = {
  data?: CustomerUser[];
  onItemClicked: (item: CustomerUser) => void;
};
export const UserListData: FC<UserListDataProps> = (props) => {
  const { isMobileScreen } = useAppResponsiveScreen();

  return props.data && props.data.length > 0 ? (
    isMobileScreen ? (
      <UserListGrid {...props} />
    ) : (
      <UserListTable {...props} />
    )
  ) : (
    <AppEmptyContentStateComponent
      description={"No hay usuarios registrados."}
    />
  );
};
