import { FC, Fragment, MouseEventHandler, useCallback, useEffect } from "react";
import { Button, Card, notification, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Gateway } from "../../../domain/type/Gateway";
import { useGatewayDeviceViewModel } from "../../GatewayDeviceViewModel";
import { ResponsiveContainer } from "../../../../../core/presentation/component/Container";
import { AppEmptyContentStateComponent } from "../../../../../core/presentation/component/State";
import { AppLoader } from "../../../../../core/presentation/component/AppLoader";

type Props = {
  gateway: Gateway;
};

export const GatewayDevicesTab: FC<Props> = ({ gateway }) => {
  const {
    gatewayDeviceList,
    onFetchListStateReceived,
    fetchListState,
    fetchList,
  } = useGatewayDeviceViewModel();

  const fetchGatewayDeviceList = useCallback(() => {
    void fetchList(gateway.id);
  }, [gateway]);

  useEffect(() => {
    void fetchGatewayDeviceList();
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Ocurrió un error al obtener los dispositivos.",
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  const onCardClicked: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const index = Number(event.currentTarget.dataset.index);
    },
    []
  );

  return (
    <ResponsiveContainer>
      <AppLoader loading={!!fetchListState && fetchListState.loading} />
      <Typography.Text type={"secondary"}>
        {gatewayDeviceList?.length} variables en este gateway
      </Typography.Text>
      <Button.Group className={"block my-2"}>
        <Button
          type={"default"}
          onClick={fetchGatewayDeviceList}
          icon={<FontAwesomeIcon icon={faRefresh} />}
        >
          Actualizar
        </Button>
      </Button.Group>
      <div className={"overflow-y-auto"}>
        {!!gatewayDeviceList && gatewayDeviceList.length > 0 ? (
          gatewayDeviceList.map((gatewayDevice, index) => (
            <Card
              onClick={onCardClicked}
              data-index={index}
              key={`device-${index}`}
              className={"my-2 cursor-pointer"}
            >
              <div className="py-2 flex flex-col">
                {gatewayDevice.Chart ? (
                  <Fragment>
                    <div className="flex flex-row gap-2">
                      <Typography.Text>
                        {gatewayDevice.Chart.description}
                      </Typography.Text>
                    </div>
                  </Fragment>
                ) : (
                  <Typography.Text>
                    Esta variable no tiene un chart.
                  </Typography.Text>
                )}
              </div>

              <div className="flex flex-row flex-wrap items-center justify-center gap-1 w-fit h-fit">
                <Typography.Title
                  level={5}
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {gatewayDevice.Device.description}
                </Typography.Title>
                <Typography.Text
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                  type={"secondary"}
                >
                  ({gatewayDevice.Device.key})
                </Typography.Text>
              </div>
            </Card>
          ))
        ) : (
          <AppEmptyContentStateComponent
            description={"No hay dispositivos agregados al gateway."}
          />
        )}
      </div>
    </ResponsiveContainer>
  );
};
