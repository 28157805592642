import { useCallback, useEffect, useMemo, useState } from "react";

export function useComponentSize() {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [component, setComponent] = useState<HTMLDivElement | null>(null);

  const ref = useCallback(
    (node: HTMLDivElement) => {
      setComponent(node);
    },
    [setComponent]
  );

  const onSizeCallback = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        setWidth(node.offsetWidth);
        setHeight(node.offsetHeight);
      }
    },
    [setWidth, setHeight]
  );

  const resizeObsever = useMemo(() => {
    return new ResizeObserver((entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        onSizeCallback(entry.target as HTMLDivElement);
      }
    });
  }, [component]);

  useEffect(() => {
    const currentComponent = component;
    const currentObserver = resizeObsever;
    if (currentComponent !== null) {
      currentObserver.unobserve(currentComponent);
      currentObserver.observe(currentComponent);
    }
    return () => {
      if (currentComponent !== null) {
        currentObserver?.unobserve(currentComponent);
      }
    };
  }, [component]);

  return { ref, width, height };
}
