import { FC } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { CustomerUser } from "../../../../features/customer_user/domain/type/CustomerUser";
import { UserListDataProps } from "./Data";
import { NavLink } from "react-router-dom";

export const UserListTable: FC<UserListDataProps> = ({
  data,
  onItemClicked,
}) => {
  const columns: ColumnsType<CustomerUser> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nombres",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Apellidos",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Usuario",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Cargo",
      render: (value, record, _) => {
        return (
          <NavLink
            className={"dark:text-white"}
            to={`/dashboard/users/permissions/${record.Role?.id}`}
          >
            {record.Role?.label}
          </NavLink>
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ];
  return (
    <Table
      onRow={(record, _) => {
        return {
          onClick: onItemClicked ? () => onItemClicked(record) : undefined,
        };
      }}
      size={"small"}
      columns={columns}
      dataSource={data}
      rowKey={"id"}
    />
  );
};
