import {
  FC,
  MouseEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Checkbox,
  notification,
  Timeline,
  Typography,
} from "antd";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L, { Map } from "leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocation,
  faRefresh,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, FormikHelpers } from "formik";
import { Form, Select, FormItem, SubmitButton } from "formik-antd";
import * as Yup from "yup";
import { GatewayLocationCard } from "./components/GatewayLocationCard";
import { isNil, toNumber } from "lodash";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { useDeviceDataViewModel } from "../../../../../../core/presentation/viewmodel/DeviceData";
import { GatewayLocation } from "../../../../../../core/domain/type/DeviceData";
import { useLocationViewModel } from "../../../../../../core/presentation/viewmodel/Location";
import { useGatewayViewModel } from "../../../../../gateway_list/presentation/ViewModel";
import { ResponsiveContainer } from "../../../../../../core/presentation/component/Container";
import { FormikAntDatePicker } from "../../../../../../core/presentation/component/FormikAntDatePicker";
import { AppLoader } from "../../../../../../core/presentation/component/AppLoader";
import { FullscreenToggle } from "../../../../../../core/presentation/component/Fullscreen";
import { Routing } from "../../../../../../core/presentation/component/RoutingMachine";

type Props = {
  vehicle: Vehicle;
};

type FetchType = {
  date?: string;
  gatewayKey?: string;
};

const kFETCH_LOCATION_SCHEMA = Yup.object({
  gatewayKey: Yup.string().required("Es necesario que selecciones un gateway."),
  date: Yup.string().required("Es necesario seleccionar una fecha."),
});

export const VehicleTrackingHistoryTab: FC<Props> = ({ vehicle }) => {
  const {
    fetchState,
    fetchDeviceLocation,
    gatewayLocation,
    onFetchStateReceived,
  } = useDeviceDataViewModel();
  const [toShowLocations, setToShowLocations] = useState<
    GatewayLocation[] | null
  >();
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>();
  const [traceRoute, setTraceRoute] = useState(false);
  const {
    address,
    fetchAddress,
    fetchAddressState,
    onFetchAddressStateReceived,
  } = useLocationViewModel();
  const {
    fetchVehicleList,
    onFetchListStateReceived,
    vehicleGateway,
    fetchListState,
  } = useGatewayViewModel();

  const onSubmit = useCallback(
    (data: FetchType, helpers: FormikHelpers<FetchType>) => {
      if (divRef.current) divRef.current.scrollIntoView();
      fetchDeviceLocation(data.gatewayKey!!, new Date(data.date!!)).then(
        (res) => {
          helpers.setSubmitting(false);
        }
      );
    },
    [fetchDeviceLocation]
  );

  const fetchVehicleGateways = useCallback(() => {
    void fetchVehicleList(vehicle.id);
  }, [vehicle, fetchVehicleList]);

  const onCenterClicked = useCallback(() => {
    if (mapRef.current && toShowLocations)
      mapRef.current.setView({
        alt: toShowLocations[0].altitude,
        lat: toShowLocations[0].latitude,
        lng: toShowLocations[0].longitude,
      });
    setSelectedAddressIndex(0);
  }, [toShowLocations, setSelectedAddressIndex]);

  useEffect(() => {
    fetchVehicleGateways();
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Error al obtener los dispositivos.",
          description: fetchListState.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!fetchState && !fetchState.loading) {
      if (fetchState.hasError) {
        notification.error({
          message: "Error al obtener los datos.",
          description: fetchState.error?.message,
        });
      } else {
        if (!!gatewayLocation && gatewayLocation.length > 0) {
          let to = gatewayLocation!!.filter((it) => !!it.latitude);
          while (to?.length > 100) {
            to = to.filter((_, id) => id % 2 !== 0);
          }
          setToShowLocations(to);
        } else {
          setToShowLocations(null);
        }
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (toShowLocations) {
      onCenterClicked();
    }
  }, [toShowLocations]);
  const divRef = useRef<HTMLDivElement>(
    null
  ) as MutableRefObject<HTMLDivElement>;
  const mapRef = useRef<Map>(null);

  const onPosSelected = useCallback(
    (index: number) => {
      if (mapRef.current && toShowLocations) {
        mapRef.current.setView({
          alt: toShowLocations[index].altitude,
          lat: toShowLocations[index].latitude,
          lng: toShowLocations[index].longitude,
        });
      }
    },
    [toShowLocations]
  );

  const onCardClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const pos = e.currentTarget.dataset["index"];
      onPosSelected(pos as any);
      setSelectedAddressIndex(toNumber(pos!!));
    },
    [onPosSelected, setSelectedAddressIndex]
  );

  useEffect(() => {
    if (!toShowLocations || isNil(selectedAddressIndex)) return;
    const loc = toShowLocations[selectedAddressIndex];
    if (!loc) return;
    void fetchAddress(loc);
  }, [selectedAddressIndex, toShowLocations]);

  useEffect(() => {
    if (!!fetchAddressState && !fetchAddressState.loading) {
      onFetchAddressStateReceived();
    }
  }, [fetchAddressState]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-y-auto"}>
      <Button.Group className={"block mb-2"}>
        <Button
          type={"default"}
          onClick={fetchVehicleGateways}
          icon={<FontAwesomeIcon icon={faRefresh} />}
        >
          Actualizar
        </Button>
      </Button.Group>
      <Typography.Text type={"secondary"}>
        Seleccionar un gateway y una fecha para obtener el historial de
        ubicación.
      </Typography.Text>
      <Card className={"my-2"}>
        <Formik<FetchType>
          initialValues={{}}
          onSubmit={onSubmit}
          validationSchema={kFETCH_LOCATION_SCHEMA}
        >
          <Form className={"w-fit"} layout={"vertical"}>
            <FormItem
              label={"Gateway"}
              name={"gatewayId"}
              help={"Seleccionar gateway"}
            >
              <Select
                placeholder={"Seleccionar gateway"}
                options={vehicleGateway as any}
                fieldNames={{
                  label: "key",
                  value: "key",
                }}
                name={"gatewayKey"}
                allowClear
              />
            </FormItem>
            <FormItem
              name={"date"}
              label={"Fecha"}
              help={"Se mostrará la ruta durante este día"}
            >
              <FormikAntDatePicker name={"date"} />
            </FormItem>
            <SubmitButton icon={<FontAwesomeIcon icon={faEye} />}>
              Visualizar
            </SubmitButton>
          </Form>
        </Formik>
      </Card>

      <AppLoader
        loading={
          (!!fetchListState && fetchListState.loading) ||
          (!!fetchState && fetchState.loading)
        }
      />
      {!!gatewayLocation && (
        <Typography.Text>
          Se tienen {gatewayLocation.length} registros de ubicación para la
          fecha seleccionada.
        </Typography.Text>
      )}

      <div className={"w-full overflow-x-hidden relative"} ref={divRef}>
        <div
          className={"absolute w-full z-20 top-0 items-center flex flex-col"}
        >
          {address && (
            <Typography.Text
              className={
                "max-w-xs text-center mt-2 bg-white p-2 bg-opacity-70 rounded-md"
              }
              type={"secondary"}
            >
              {/* {address.display_name} */}
            </Typography.Text>
          )}
        </div>
        <div
          className={
            "z-20 absolute top-0 right-0 p-3 flex flex-col gap-2 h-full items-end justify-start"
          }
        >
          <FullscreenToggle containerRef={divRef} />
          <Button
            type={"primary"}
            icon={<FontAwesomeIcon icon={faLocation} />}
            onClick={onCenterClicked}
          >
            Últ. ubicación
          </Button>
          <Checkbox
            checked={traceRoute}
            onChange={(e) => setTraceRoute(e.target.checked)}
          >
            Trazar ruta
          </Checkbox>

          {toShowLocations && (
            <Timeline
              className={
                "max-w-xs flex flex-col gap-2 p-2 overflow-x-hidden overflow-y-auto h-1/2"
              }
            >
              {toShowLocations.map((it, idx) => {
                return (
                  <Timeline.Item key={`loc-${idx}`}>
                    <GatewayLocationCard
                      selected={selectedAddressIndex === idx}
                      index={idx}
                      location={it}
                      onClick={onCardClick}
                    />
                  </Timeline.Item>
                );
              })}
            </Timeline>
          )}
        </div>
        <MapContainer
          ref={mapRef}
          className={"w-full min-h-screen z-10"}
          center={[-11.9554568, -77.0004833]}
          zoom={13}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {traceRoute ? (
            <Routing
              onPositionSelected={onPosSelected}
              locations={toShowLocations}
              lineOptions={{
                styles: [{ color: "#f8aa19", opacity: 1, weight: 5 }],
              }}
            />
          ) : !isNil(selectedAddressIndex) ? (
            <Marker
              icon={L.icon({
                iconUrl: `${process.env.PUBLIC_URL}/marker.png`,
                iconSize: [22, 32],
                iconAnchor: [16, 32],
              })}
              position={{
                lat: toShowLocations![selectedAddressIndex].latitude,
                lng: toShowLocations![selectedAddressIndex].longitude,
              }}
            />
          ) : null}
        </MapContainer>
      </div>
    </ResponsiveContainer>
  );
};
