import {
  FC,
  Fragment,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ButtonProps, Button, Typography } from "antd";
import { CircularProgress } from "./Progress";

type Props = {
  onClick: () => void;
  disabledAtRender?: boolean;
  maxSeconds: number;
  buttonProps?: ButtonProps;
};

export const CountDownButton: FC<PropsWithChildren<Props>> = ({
  onClick,
  disabledAtRender,
  maxSeconds,
  children,
  buttonProps,
}) => {
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);
  const [elapsedTime, setElapsedTime] = useState<number | null>(null);
  const timer = useRef(null) as MutableRefObject<NodeJS.Timer | null>;
  const onTimerTick = useCallback(() => {
    setElapsedTime((old) => {
      if (old === maxSeconds - 1) {
        clearInterval(timer.current!);
        setResendEnabled(true);
        timer.current = null;
        return null;
      } else {
        setResendEnabled(false);
        if (old === null) return 0;
        return old + 1;
      }
    });
  }, []);

  const onButtonClicked = useCallback(() => {
    timer.current = setInterval(onTimerTick, 1000);
    onClick();
  }, []);

  useEffect(() => {
    if (disabledAtRender) timer.current = setInterval(onTimerTick, 1000);
    return () => {
      if (timer !== null) clearTimeout(timer.current!);
    };
  }, []);

  return (
    <Fragment>
      <Button
        disabled={!resendEnabled}
        {...buttonProps}
        onClick={onButtonClicked}
      >
        {children}
      </Button>
      {elapsedTime ? (
        <div className="flex flex-row justify-center items-center gap-1">
          <CircularProgress
            size={35}
            showInfo
            type={"circle"}
            format={(_) => {
              return (
                <Typography.Text className="font-mono text-xs p-0 m-0">
                  {maxSeconds - elapsedTime}
                </Typography.Text>
              );
            }}
            percent={(elapsedTime / maxSeconds) * 100}
          />
          <Typography.Text className={"p-0 m-0"}>
            seg. restantes para reenviar el código
          </Typography.Text>
        </div>
      ) : null}
    </Fragment>
  );
};
