import { FC } from "react";
import { Typography } from "antd";
import * as yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import { Form, FormItem, Input, SubmitButton } from "formik-antd";
import { FindCustomerByEmailData } from "../../domain/type/Customer";

type Props = {
  onSubmit: (values: FindCustomerByEmailData) => Promise<boolean | undefined>;
};

const kDOMAIN_CHECK_FORM_VALIDATOR = yup.object({
  email: yup
    .string()
    .required("Ingresa un email")
    .email("Ingresa un email válido"),
});

export const FindCustomerByEmailForm: FC<Props> = ({ onSubmit }) => {
  return (
    <div className={"flex flex-col justify-start items-start w-full gap-4"}>
      <span className="text-neutral-800 dark:text-neutral-200 text-lg uppercase font-medium">
        Iniciar Sesión
      </span>
      <Formik<FindCustomerByEmailData>
        validateOnChange
        initialValues={{
          email: "",
        }}
        validationSchema={kDOMAIN_CHECK_FORM_VALIDATOR}
        onSubmit={(values, formikHelpers) => {
          onSubmit(values).then((it) => {
            if (it) formikHelpers.resetForm();
            formikHelpers.setSubmitting(false);
          });
        }}
      >
        <Form layout={"vertical"} className={"w-full flex flex-col"}>
          <FormItem name={"email"}>
            <Input
              name={"email"}
              allowClear
              prefix={<FontAwesomeIcon icon={faAt} />}
              placeholder={"Correo Electrónico"}
            />
          </FormItem>
          <RouterLink to={"/recover-password"} className={"no-underline w-fit"}>
            <Typography.Text
              style={{ padding: 0, margin: 0 }}
              className="text-primary"
              underline={false}
            >
              ¿Olvidaste tu contraseña? Click aquí
            </Typography.Text>
          </RouterLink>
          <SubmitButton
            type="primary"
            className={"ml-auto"}
            icon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            Siguiente
          </SubmitButton>
        </Form>
      </Formik>
    </div>
  );
};
