import { FC, Fragment, useEffect, useState } from "react";
import { useRecoveryRequestViewModel } from "./ViewModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Typography, notification } from "antd";
import { CustomerLogo } from "../../../core/presentation/component/CustomerLogo";
import { PasswordRecoveryPageForm } from "./component/Form";

type Props = {};

export const ForgotPasswordPage: FC<Props> = ({}) => {
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const navigate = useNavigate();
  const { sendRequest, requestState, onRequestStateReceived } =
    useRecoveryRequestViewModel();

  useEffect(() => {
    if (!!requestState && !requestState.loading) {
      if (requestState.hasError) {
        notification.error({
          message: "Solicitud de Cambio de contraseña",
          description: requestState.error?.message,
        });
      } else {
        setMessageSent(true);
      }
      onRequestStateReceived();
    }
  }, [requestState]);

  return (
    <div className="flex flex-col items-start md:max-w-sm lg:max-w-md px-6 py-10 h-fit w-full bg-white shadow-lg dark:bg-neutral-700 gap-4">
      <div className="max-w-xs overflow-hidden">
        <CustomerLogo />
      </div>

      {messageSent ? (
        <Fragment>
          <span className="text-neutral-800 dark:text-neutral-200 text-lg uppercase font-medium">
            Mensaje enviado
          </span>
          <Typography.Text>
            Se ha enviado un correo electrónico detallando las instrucciones
            para restablecer su contraseña.
          </Typography.Text>
          <Typography.Text className={"text-4xl text-amber-500"}>
            <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
          </Typography.Text>
        </Fragment>
      ) : (
        <Fragment>
          <span className="text-neutral-800 dark:text-neutral-200 text-lg uppercase font-medium">
            Recuperar Contraseña
          </span>
          <Typography.Text>
            Te enviaremos instrucciones a tu correo electrónico. Ingresa tu
            nombre de usuario o email a continuación:
          </Typography.Text>

          <PasswordRecoveryPageForm
            onSubmit={sendRequest}
            onBackPressed={() => navigate(-1)}
          />
        </Fragment>
      )}
    </div>
  );
};
