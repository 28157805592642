import { FC, MouseEventHandler, useCallback } from "react";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faArrowLeft,
  faBars,
  faSun,
  faMoon,
} from "@fortawesome/free-solid-svg-icons";
import { useAppTheme } from "../../../../../app/hook/Theme";
import { useDashboardHeader } from "../hook/Header";

type Props = {
  onActionClick: (key: string) => void;
};

export const DashboardLayoutHeaderSmall: FC<Props> = ({ onActionClick }) => {
  const { isDarkThemeEnabled, switchDarkTheme } = useAppTheme();
  const { title } = useDashboardHeader();

  const onClick = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      onActionClick(e.currentTarget.dataset["key"] as string);
    },
    [onActionClick]
  );

  return (
    <div className={"flex flex-col w-full"}>
      <div className={"flex flex-col p-0"}>
        <div
          className={
            "w-full h-fit flex flex-row gap-2 py-3 pl-2 place-items-center"
          }
        >
          <Button
            type={"text"}
            shape={"circle"}
            icon={<FontAwesomeIcon icon={faBars} />}
            data-key={"menu"}
            onClick={onClick}
          />
          <Button
            className={"text-neutral-400 mr-2"}
            type={"text"}
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={onClick}
            data-key={"back"}
          />

          <Button.Group className={"ml-auto place-items-center"}>
            <Button
              shape={"circle"}
              type={"text"}
              ghost={true}
              icon={<FontAwesomeIcon icon={faUser} />}
              data-key={"user"}
              onClick={onClick}
            />
            <Button
              shape="circle"
              type={isDarkThemeEnabled ? "primary" : "default"}
              icon={
                <FontAwesomeIcon icon={isDarkThemeEnabled ? faSun : faMoon} />
              }
              onClick={switchDarkTheme}
            />
          </Button.Group>
        </div>
      </div>
    </div>
  );
};
