import { RemoteVehicleSource } from "../source/Remote";

export class VehicleRepository {
  static async getVehicleList() {
    return await RemoteVehicleSource.getAllVehicles();
  }

  static async getVehicle(id: number) {
    return await RemoteVehicleSource.getVehicle(id);
  }
}
