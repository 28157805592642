import { FC, useMemo } from "react";
import { Tabs, TabsProps } from "antd";
import { VehicleTrackingTab } from "../tracking/TabTracking";
import { VehicleDeviceTab } from "../device/TabDevice";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { TabContentBox } from "../../../../../../core/presentation/component/TabContentBox";

type Props = {
  vehicle: Vehicle;
};

export const VehicleDashboardTab: FC<Props> = ({ vehicle }) => {
  const items = useMemo<TabsProps["items"]>(() => {
    return [
      {
        key: "1",
        label: "Variables",
        children: <VehicleDeviceTab vehicle={vehicle} />,
      },
      {
        key: "2",
        label: "Tracking",
        children: <VehicleTrackingTab vehicle={vehicle} />,
      },
    ];
  }, [vehicle]);
  return (
    <TabContentBox>
      <Tabs items={items} defaultActiveKey={"1"} />
    </TabContentBox>
  );
};
