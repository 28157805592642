import { Button, Layout, Switch, Typography } from "antd";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

import { useAppTheme } from "../../../../app/hook/Theme";
import { MainLayoutBackgroundCarousel } from "./component/Carousel";
import { useAppResponsiveScreen } from "../../../../app/hook/Breakpoint";

const { Content, Footer, Header } = Layout;
export const MainLayout: FC = () => {
  const { isMobileScreen } = useAppResponsiveScreen();
  const { isDarkThemeEnabled, switchDarkTheme } = useAppTheme();

  return (
    <Layout className={"w-screen h-screen overflow-hidden z-50"}>
      {!isMobileScreen ? (
        <Header
          className={
            "flex flex-row pl-4 pr-3 absolute bg-transparent top-0 w-full z-50"
          }
        >
          <div className={"w-full h-full flex flex-row place-items-center"}>
            <Button.Group className={"place-items-center ml-auto"}>
              <Switch
                checked={isDarkThemeEnabled}
                checkedChildren={<FontAwesomeIcon icon={faSun} />}
                unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
                onChange={switchDarkTheme}
              />
            </Button.Group>
          </div>
        </Header>
      ) : (
        <div className={"absolute bg-transparent top-0 w-full z-50 px-4 py-4"}>
          <div className={"flex flex-col p-0"}>
            <div
              className={"w-full h-fit flex flex-row gap-2 place-items-center"}
            >
              <Button.Group className={"ml-auto place-items-center"}>
                <Switch
                  checked={isDarkThemeEnabled}
                  checkedChildren={<FontAwesomeIcon icon={faSun} />}
                  unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
                  onChange={switchDarkTheme}
                />
              </Button.Group>
            </div>
          </div>
        </div>
      )}
      <Content className={"w-full h-full flex flex-col overflow-hidden"}>
        <Layout className="overflow-hidden w-full h-full relative">
          <div className="absolute w-full h-full top-0 right-0">
            <MainLayoutBackgroundCarousel />
          </div>
          <div className="overflow-hidden md:grid md:grid-cols-2 z-30 w-full h-full">
            <div className="hidden md:block overflow-hidden w-full h-full"></div>
            <div className="h-full flex flex-col relative items-center justify-center">
              <div className="hidden md:block md:absolute w-full h-full right-0 top-0 bg-transparent    backdrop-blur-md z-40"></div>
              <div className="w-full flex-1 flex items-center justify-center z-50">
                <Outlet />
              </div>
            </div>
          </div>
        </Layout>

        <Footer
          className={
            "flex place-content-center w-full py-1 m-0 z-50 bg-primary"
          }
        >
          <span
            style={{ padding: 0, margin: 0 }}
            className="text-primary-text text-center"
          >
            2023 ACME & CIA. Todos los derechos reservados.
          </span>
        </Footer>
      </Content>
    </Layout>
  );
};
