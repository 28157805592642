import { FC, useEffect, useMemo, useRef, useState } from "react";
import { LineChart } from "./Line";
import { HPlotChart } from "./HPlot";
import { BarChart2 } from "./Bar2";
import { AreaChart } from "./Area";
import { GaugeChart } from "./Gauge";
import { ChartData, ChartSchemaDefinition } from "../../../domain/type/Chart";
import { AppEmptyContentStateComponent } from "../State";

type Props<T extends ChartData> = {
  schema: ChartSchemaDefinition;
  data: Array<T>;
  title: string;
  id: string;
};

export function DeviceDataRender<T extends ChartData>({
  data,
  schema,
  title,
  id,
}: Props<T>) {
  const [type, setType] = useState<string>();
  useEffect(() => {
    if (!!type) {
      if (type !== schema.chartType) setType(schema.chartType);
    } else {
      setType(schema.chartType);
    }
  }, [schema]);

  const Element = useMemo(() => {
    const chartType = type;
    switch (chartType) {
      case "line":
        return LineChart;
      case "area":
        return AreaChart;
      case "bar":
        return BarChart2;
      case "accumulated":
        return HPlotChart;
      case "gauge":
        return GaugeChart;
      default:
        return InvalidGraphic;
    }
  }, [type]);

  return (
    <Element tooltipTitle={title} items={data as any} schema={schema} id={id} />
  );
}

const InvalidGraphic: FC<any> = ({ tooltipTile, schema }) => {
  return (
    <div className={"flex flex-col items-center justify-center-4"}>
      <AppEmptyContentStateComponent
        description={`La configuración para ${tooltipTile} no es válida.`}
        title={"Gráfico inválido"}
      />
    </div>
  );
};
export function DeviceDataPreRender<T extends ChartData>({
  data,
  schema,
  title,
  id,
}: Props<T>) {
  const Element = useMemo(() => {
    const chartType = schema.chartType;
    switch (chartType) {
      case "line":
        return LineChart;
      case "area":
        return AreaChart;
      case "bar":
        return BarChart2;
      case "hplot":
        return HPlotChart;
      case "gauge":
        return GaugeChart;
      default:
        return InvalidGraphic;
    }
  }, [schema]);

  return (
    <Element tooltipTitle={title} items={data as any} schema={schema} id={id} />
  );
}
