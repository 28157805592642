import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useState,
  useEffect,
} from "react";

export type AppResponsiveScreenContextValue = {
  isExtraSmallScreen?: boolean;
  isSmallScreen?: boolean;
  isMediumScreen?: boolean;
  isLargeScreen?: boolean;
  isExtraLargeScreen?: boolean;
  isMobileScreen?: boolean;
  isDesktopScreen?: boolean;
  screenWidth: number;
};

export const screens = {
  xs: 0,
  sm: 480,
  md: 640,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export const AppResponsiveScreenContext =
  createContext<AppResponsiveScreenContextValue>({ screenWidth: 0 });

export const AppResponsiveScreenProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [screenValue, setScreenValue] =
    useState<AppResponsiveScreenContextValue>({
      screenWidth: window.innerWidth,
    });

  const windowSizeListener = useCallback(() => {
    const width = window.innerWidth;
    let isXs = false,
      isSm = false,
      isMd = false,
      isLg = false,
      isXl = false,
      isMb = false,
      isDt = false;
    switch (true) {
      case width >= screens.xs && width <= screens.sm:
        isXs = true;
        break;
      case width >= screens.sm && width <= screens.md:
        isSm = true;
        break;
      case width >= screens.md && width <= screens.lg:
        isMd = true;
        break;
      case width >= screens.lg && width <= screens.xl:
        isLg = true;
        break;
      case width >= screens.xl && width <= Number.MAX_VALUE:
        isXl = true;
        break;
    }
    isMb = isXs || isSm || isMb;
    isDt = isLg || isXl;
    setScreenValue({
      isExtraSmallScreen: isXs,
      isSmallScreen: isSm,
      isMediumScreen: isMd,
      isLargeScreen: isLg,
      isExtraLargeScreen: isXl,
      isMobileScreen: isMb,
      isDesktopScreen: isDt,
      screenWidth: width,
    });
  }, [setScreenValue]);

  useEffect(() => {
    window.addEventListener("resize", windowSizeListener);
    windowSizeListener();
    return () => {
      window.removeEventListener("resize", windowSizeListener);
    };
  }, []);

  return (
    <AppResponsiveScreenContext.Provider value={screenValue}>
      {children}
    </AppResponsiveScreenContext.Provider>
  );
};
