import { FC } from "react";
import * as yup from "yup";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { Form, FormItem, Input, SubmitButton } from "formik-antd";

export type PasswordRecoveryFormData = {
  password: string;
  passwordConfirmation: string;
};

type Props = {
  onSubmit: (data: PasswordRecoveryFormData) => Promise<boolean | undefined>;
};

const kPASSWORD_UPDATE_VALIDATOR = yup.object({
  password: yup
    .string()
    .required("Escriba una nueva contraseña.")
    .min(8, "La contraseña debe contener al menos 8 caracteres."),
  passwordConfirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), undefined],
      "Ambas contraseñas deben coincidir."
    ),
});

export const PasswordRecoveryForm: FC<Props> = ({ onSubmit }) => {
  return (
    <div className={"flex flex-col justify-start items-start w-full gap-4"}>
      <span className="text-neutral-800 dark:text-neutral-200 text-lg uppercase font-medium">
        Actualizar Contraseña
      </span>
      <Formik
        initialValues={{} as any}
        onSubmit={(values, helpers) => {
          onSubmit(values).then((it) => {
            if (it) helpers.resetForm();
            helpers.setSubmitting(false);
          });
        }}
        validationSchema={kPASSWORD_UPDATE_VALIDATOR}
      >
        <Form className={"w-full flex flex-col"}>
          <FormItem name={"password"}>
            <Input.Password
              required
              name="password"
              placeholder="Nueva contraseña"
            />
          </FormItem>
          <FormItem name={"passwordConfirmation"}>
            <Input.Password
              required
              name="passwordConfirmation"
              placeholder="Repetir contraseña"
            />
          </FormItem>

          <SubmitButton
            type={"primary"}
            className="ml-auto"
            icon={<FontAwesomeIcon icon={faCheck} />}
          >
            Confirmar
          </SubmitButton>
        </Form>
      </Formik>
    </div>
  );
};
