import { Card, notification } from "antd";
import { FC, useEffect } from "react";
import { useOverviewViewModel } from "../ViewModel";
import { MultipleGatewayMap } from "../../../../core/presentation/component/GatewayMap";

export const ConnectedVehicles: FC = () => {
  const {
    fetchConnectedGateways,
    fetchConnectedGatewaysState,
    onFetchConnectedGatewaysStateReceived,
    connectedGateways,
  } = useOverviewViewModel();

  useEffect(() => {
    void fetchConnectedGateways();
  }, []);

  useEffect(() => {
    if (!!fetchConnectedGatewaysState && !fetchConnectedGatewaysState.loading) {
      if (fetchConnectedGatewaysState.hasError) {
        notification.error({
          message: "Error",
          description: fetchConnectedGatewaysState.error?.message,
        });
      }
      onFetchConnectedGatewaysStateReceived();
    }
  }, [fetchConnectedGatewaysState]);

  return (
    <Card
      bodyStyle={{
        padding: 0,
        width: "100%",
        height: "100%",
      }}
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
      hoverable
      bordered={false}
    >
      <div className="h-full w-full">
        <MultipleGatewayMap gatewayList={connectedGateways} />
      </div>
    </Card>
  );
};
