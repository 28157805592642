import moment from "moment";
import { DateTime } from "luxon";

export class DateUtil {
  static fastFormatDate(date: Date | number | string, format: string) {
    const type = typeof date;
    const dt =
      type === "string"
        ? DateTime.fromFormat(date as string, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
        : type === "number"
        ? DateTime.fromMillis(date as number)
        : DateTime.fromJSDate(date as Date);
    return dt.setZone("America/Lima").setLocale("es").toFormat(format);
  }

  static formatDate(range: [Date, Date] | undefined, date: Date) {
    if (!!range && range.length === 2) {
      const [rangeStart, rangeEnd] = range as [Date, Date];
      if (moment(date).isBetween(rangeStart, rangeEnd, null, "[]")) {
        const diff = moment(rangeEnd).diff(moment(rangeStart), "days");
        if (diff === 0) {
          return moment(date).format("HH:mm:ss");
        } else if (diff < 7 && moment(rangeStart).isSame(rangeEnd, "week")) {
          return moment(date).format("dddd HH:mm:ss");
        } else if (moment(rangeStart).isSame(rangeEnd, "month")) {
          return moment(date).format("dddd DD HH:mm");
        } else if (moment(rangeStart).isSame(rangeEnd, "year")) {
          return moment(date).format("DD/MM HH:mm");
        } else {
          return moment(date).format("DD/MM/YY HH:mm");
        }
      } else {
        return moment(date).format("DD/MM/YY HH:mm");
      }
    } else {
      return moment(date).format("DD/MM/YY HH:mm");
    }
  }

  static extractMonth(date: Date) {
    return this.fastFormatDate(date, "MMMM").toUpperCase();
  }
}
