import { useState } from "react";
import { CustomerUserRepository } from "../data/repository/CustomerUser";
import { CustomerUser } from "../domain/type/CustomerUser";
import { AppTaskState } from "../../../app/domain/type/State";
import { AppTaskStateG } from "../../../app/domain/model/State";

export function useCustomerUserViewModel() {
  const [customerUser, setCustomerUser] = useState<CustomerUser | null>(null);
  const [fetchUserState, setFetchUserState] =
    useState<AppTaskState<boolean> | null>(null);
  const [fetchListState, setFetchListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [userList, setUserList] = useState<Array<CustomerUser> | undefined>();

  async function fetchList() {
    if (fetchListState?.loading) return;
    setFetchListState(AppTaskStateG.loading());
    try {
      const users = await CustomerUserRepository.getUserList();
      if (users!.ok) {
        setUserList(users!.data!!);
        setFetchListState(AppTaskStateG.success(true));
      } else {
        setFetchListState(AppTaskStateG.error(new Error(users!.message)));
      }
    } catch (e: any) {
      setFetchListState(AppTaskStateG.error(e));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  async function fetchUser(id: number) {
    if (fetchUserState?.loading) return;
    setFetchUserState(AppTaskStateG.loading());
    try {
      const result = await CustomerUserRepository.getUser(id);
      if (result!!.ok) {
        setCustomerUser(result!!.data!!);
        setFetchUserState(AppTaskStateG.success(true));
      } else {
        setFetchUserState(AppTaskStateG.error(new Error(result!!.message)));
      }
    } catch (e: any) {
      setFetchUserState(AppTaskStateG.error(e));
    }
  }

  function onFetchUserStateReceived() {
    setFetchUserState(null);
  }

  return {
    customerUser,
    fetchUser,
    fetchUserState,
    onFetchUserStateReceived,
    fetchListState,
    fetchList,
    onFetchListStateReceived,
    userList,
  };
}
