import { FC, useMemo } from "react";
import Plot from "react-plotly.js";
import { Data, Layout } from "plotly.js";
import { ChartData2, ChartSchemaDefinition } from "../../../domain/type/Chart";
import { ChartsUtil } from "../../../util/Charts";
import { useAppTheme } from "../../../../app/hook/Theme";
import { DateUtil } from "../../../util/Date";
type Props = {
  tooltipTitle: string;
  items: Array<ChartData2>;
  schema?: ChartSchemaDefinition;
  id: string;
};
export const HPlotChart: FC<Props> = ({ tooltipTitle, items, schema }) => {
  const colors = useMemo(
    () => ChartsUtil.generateChartColors(schema),
    [schema]
  );
  const { isDarkThemeEnabled } = useAppTheme();
  const layout = useMemo<Partial<Layout>>(
    () => ({
      barmode: "stack",
      xaxis: {
        title: {
          text: "n° Horas",
        },
        showline: false,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      showlegend: false,
      legend: { orientation: "v" },
      hovermode: "closest",
      hoverlabel: {
        font: {
          color: isDarkThemeEnabled ? "#FBFBFB" : "#424242",
          family: "'Quicksand', sans-serif",
        },
        bgcolor: isDarkThemeEnabled ? "#424242" : "#FBFBFB",
        bordercolor: "transparent",
      },
      font: {
        family: "'Quicksand', sans-serif",
        color: isDarkThemeEnabled ? "#FBFBFB" : "#424242",
      },
      colorway: [],
      autosize: true,
      margin: {
        pad: 10,
      },
    }),
    [isDarkThemeEnabled]
  );

  const data: Data[] = items.map((it) => {
    return {
      x: [0.1 + it.repeats],
      y: [DateUtil.extractMonth(it.start)],
      name: it.name,
      orientation: "h",
      marker: {
        color: colors ? colors[it.name] : "#FFF",
        width: 1,
        line: {
          width: 1,
        },
      },
      type: "bar",
      showlegend: true,
      hovertemplate:
        "<b>Horas</b>: " +
        it.repeats.toFixed(2) +
        "<br><b>Inicio: </b>" +
        DateUtil.fastFormatDate(it.start, "DDD HH:mm") +
        "<br><b>Fin: </b>" +
        DateUtil.fastFormatDate(it.end, "DDD HH:mm"),
    };
  });
  return <Plot data={data} layout={layout} />;
};
