import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import { Gateway, OnlineGateway } from "../../domain/type/Gateway";
import { ApiService } from "../../../../app/service/RemoteClient";

export class RemoteGatewaySource extends BaseRemoteSource {
  static async getGateway(
    id: number
  ): Promise<RemoteSourceResponse<OnlineGateway>> {
    try {
      const gateway = await ApiService.postWithAuth("/gateway/get", { id });
      this.checkResponseCredentials(gateway);
      return gateway.data as RemoteSourceResponse<OnlineGateway>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
  static async getAllGateways(): Promise<RemoteSourceResponse<Gateway[]>> {
    try {
      const gatewayList = await ApiService.getWithAuth("/gateway/list");
      this.checkResponseCredentials(gatewayList);
      return gatewayList.data as RemoteSourceResponse<Gateway[]>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getVehicleGateways(
    vehicleId: number | undefined
  ): Promise<RemoteSourceResponse<Gateway[]>> {
    try {
      const gatewayList = await ApiService.postWithAuth(
        "/gateway/vehicle_list",
        { vehicleId }
      );
      this.checkResponseCredentials(gatewayList);
      return gatewayList.data as RemoteSourceResponse<Gateway[]>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
