import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { CustomerUser } from "../../domain/type/CustomerUser";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import { ApiService } from "../../../../app/service/RemoteClient";

export class RemoteCustomerUserSource extends BaseRemoteSource {
  static async getUser(
    id: number
  ): Promise<RemoteSourceResponse<CustomerUser> | undefined> {
    try {
      const user = await ApiService.postWithAuth("/customer_user/get", {
        id,
      });
      this.checkResponseCredentials(user);
      return user.data as RemoteSourceResponse<CustomerUser>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerUserList(): Promise<
    RemoteSourceResponse<CustomerUser[]> | undefined
  > {
    try {
      const userList = await ApiService.getWithAuth("/customer_user/list");
      this.checkResponseCredentials(userList);
      return userList.data as RemoteSourceResponse<CustomerUser[]>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
