import { FC } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { GatewayListDataProps } from "./Data";
import { Gateway } from "../../../gateway/domain/type/Gateway";

export const GatewayListTable: FC<GatewayListDataProps> = ({
  data,
  onItemClicked,
}) => {
  const columns: ColumnsType<Gateway> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Identificador",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Vehículo",
      key: "vehicle",
      sorter: (a, b) =>
        a.Vehicle?.name.localeCompare(b.Vehicle?.name ?? "") ?? 0,
      render: (text, record) => {
        return record.Vehicle?.name;
      },
    },
  ];
  return (
    <Table
      onRow={(record, _) => {
        return {
          onClick: () => onItemClicked(record),
        };
      }}
      size={"small"}
      columns={columns}
      dataSource={data!!}
      rowKey={"id"}
    />
  );
};
