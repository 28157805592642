import { ThemeConfig } from "antd/lib/config-provider";
import { AppColorPalette } from "../type/AppPalette";

export class AppAntThemeGenerator {
  palette: AppColorPalette;
  constructor(_palette: AppColorPalette) {
    this.palette = _palette;
  }

  build(): ThemeConfig {
    throw new Error("Not implemented!");
  }

  mix(b: AppAntThemeGenerator): ThemeConfig {
    return {
      ...this.build(),
      ...b.build(),
    };
  }
}
