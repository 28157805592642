import L from "leaflet";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";
import { useEffect, useRef } from "react";
import { GatewayLocation } from "../../domain/type/DeviceData";

type Props = {
  lineOptions: Partial<L.Routing.RoutingControlOptions["lineOptions"]>;
  locations: GatewayLocation[] | undefined | null;
  onPositionSelected: (index: number) => void;
};

export function Routing({ locations, lineOptions, onPositionSelected }: Props) {
  const map = useMap();
  const controlRef = useRef<L.Routing.Control | undefined>();
  useEffect(() => {
    if (!map) return;
    if (!locations || locations.length <= 0) return;
    const waypoints = locations.map(
      (it) => new L.LatLng(it.latitude, it.longitude)
    );
    const routingControl = L.Routing.control({
      addWaypoints: false,
      //@ts-ignore
      draggableWaypoints: false,
      fitSelectedRoutes: true,
      showAlternatives: false,
      waypoints,
      lineOptions: lineOptions as any,
      //@ts-ignore
      language: "es",
      //@ts-ignore
      createMarker: function (i, waypoint, n) {
        const markerIcon = L.icon({
          iconUrl: `${process.env.PUBLIC_URL}/marker.png`,
          iconSize: [22, 32],
          iconAnchor: [16, 32],
        });
        const marker = L.marker(waypoint.latLng, { icon: markerIcon });
        marker.on("click", function () {
          onPositionSelected(i);
        });
        return marker;
      },
      show: false,
    }).addTo(map);
    controlRef.current = routingControl;
    return () => {
      map.removeControl(routingControl);
    };
  }, [map, locations]);

  return null;
}
