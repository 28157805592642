import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faIdCard,
  faTag,
  faLocation,
  faCode,
  faGlobe,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { useAppCustomer } from "../../../../app/hook/Customer";
import { ResponsiveContainer } from "../../../../core/presentation/component/Container";
import {
  DescriptionsItem,
  DescriptionsList,
} from "../../../../core/presentation/component/DescriptionsList";
import { DateUtil } from "../../../../core/util/Date";

export const CustomerMain: FC = () => {
  const { customer } = useAppCustomer();

  return (
    <ResponsiveContainer>
      <DescriptionsList rowKey={"customer"}>
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faIdCard} />}
          title={"ID"}
          label={customer?.id}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faBuilding} />}
          title={"Razón Social"}
          label={customer?.businessName}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"RUC"}
          label={customer?.ruc}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faGlobe} />}
          title={"Subdominio"}
          label={customer?.subdomain}
          itemKey={"subdomain"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faLocation} />}
          title={"Dirección"}
          label={customer?.address}
          itemKey={"address"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faCode} />}
          title={"Código Postal"}
          label={customer?.postalCode}
          itemKey={"postalCode"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faCalendar} />}
          title={"Cliente desde"}
          label={DateUtil.fastFormatDate(customer?.createdAt!!, "dd MMM yyyy")}
        />
      </DescriptionsList>
    </ResponsiveContainer>
  );
};
