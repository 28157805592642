import { FC, PropsWithChildren, useEffect } from "react";
import { DashboardRoutesProvider } from "./context/Routes";
import { DashboardLayoutComponent } from "./component/DahboardLayout";
import { DashboardHeaderProvider } from "./context/Header";

export const DashboardLayout: FC<PropsWithChildren> = () => {
  return (
    <DashboardRoutesProvider>
      <DashboardHeaderProvider>
        <DashboardLayoutComponent />
      </DashboardHeaderProvider>
    </DashboardRoutesProvider>
  );
};
