import { useEffect, useMemo } from "react";
import { useDashboardHeader } from "../../../core/layout/dashboard/presentation/hook/Header";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { ConnectedUsers } from "./components/ConnectedUsers";
import { ConnectedGateways } from "./components/ConnectedGateways";
import { Responsive } from "react-grid-layout";
import { useComponentSize } from "../../../app/hook/Size";
import { useDashboardAllowedRoutes } from "../../../core/layout/dashboard/presentation/hook/Routes";
import { OverviewLayoutUseCase } from "../domain/usecase/OverviewLayout";
import { ConnectedVehicles } from "./components/ConnectedVehicles";
import { DashboardBillingAccount } from "./components/DashboardBillingAccount";
import { FloatButton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../app/domain/model/Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";

export const OverviewPage = () => {
  const { setTitle } = useDashboardHeader();
  const { ref, width } = useComponentSize();
  const { allowedRoutes } = useDashboardAllowedRoutes();
  const { layoutDragLocked } = useSelector(
    (state: RootState) => state.overview
  );
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    setTitle("Dashboard");
  }, []);

  const overviewLayout = useMemo(() => {
    return OverviewLayoutUseCase.generateLayout(allowedRoutes);
  }, [allowedRoutes, width]);

  return (
    <ResponsiveContainer
      className={"w-full h-fit overflow-visible"}
      divRef={ref}
    >
      <FloatButton
        icon={<FontAwesomeIcon icon={layoutDragLocked ? faUnlock : faLock} />}
        onClick={dispatch.overview.switchLayoutDragLocked}
        type="primary"
        className="lg:mr-16"
      />
      <Responsive
        layouts={{
          lg: overviewLayout.lgLayout,
          md: overviewLayout.mdLayout,
          sm: overviewLayout.smLayout,
          xs: overviewLayout.smLayout,
        }}
        isDraggable={!layoutDragLocked}
        width={width}
        className="layout"
        rowHeight={50}
        breakpoints={{ lg: 996, md: 768, sm: 480, xs: 0 }}
        cols={{ lg: 12, md: 8, sm: 4, xs: 4 }}
      >
        {allowedRoutes.connected_users && (
          <div key={"connected_users"} className="w-full h-full">
            <ConnectedUsers />
          </div>
        )}
        {allowedRoutes.connected_gateways && (
          <div key={"connected_gateways"} className="w-full h-full">
            <ConnectedGateways />
          </div>
        )}
        {allowedRoutes.vehicle_list && (
          <div key={"vehicles_map"} className="w-full h-full">
            <ConnectedVehicles />
          </div>
        )}
        {allowedRoutes.billing_account && (
          <div key={"billing_account"} className="w-full h-full">
            <DashboardBillingAccount />
          </div>
        )}
      </Responsive>
    </ResponsiveContainer>
  );
};
