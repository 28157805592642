import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import {
  GatewayActionList,
  GatewayActionRequest,
  GatewayActionRequestResponseData,
  GatewayScanRequest,
} from "../../domain/type/GatewayActions";
import { ApiService } from "../../../../app/service/RemoteClient";

export class RemoteGatewayActionSource extends BaseRemoteSource {
  static async getGatewayActions(
    id: number
  ): Promise<RemoteSourceResponse<GatewayActionList>> {
    try {
      const response = await ApiService.postWithAuth("/gateway_action/get", {
        id,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<GatewayActionList>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async requestAction(request: GatewayActionRequest) {
    try {
      const response = await ApiService.postWithAuth(
        "/gateway_action/request",
        request
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<GatewayActionRequestResponseData>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async requestScanAction(request: GatewayScanRequest) {
    try {
      const response = await ApiService.postWithAuth(
        "/gateway_action/scan",
        request
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<GatewayActionRequestResponseData>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
