import { FC, MouseEventHandler, useCallback, useEffect } from "react";
import { Button, Card, notification, Tag, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faServer } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Vehicle } from "../../../domain/type/Vehicle";
import { useGatewayViewModel } from "../../../../gateway_list/presentation/ViewModel";
import { ResponsiveContainer } from "../../../../../core/presentation/component/Container";
import { AppLoader } from "../../../../../core/presentation/component/AppLoader";
import { AppEmptyContentStateComponent } from "../../../../../core/presentation/component/State";

type Props = {
  vehicle: Vehicle;
};

export const VehicleGatewayTab: FC<Props> = ({ vehicle }) => {
  const {
    fetchVehicleList,
    fetchListState,
    vehicleGateway,
    onFetchListStateReceived,
  } = useGatewayViewModel();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: fetchListState.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  const fetchVehicleGatewayList = useCallback(() => {
    void fetchVehicleList(vehicle.id);
  }, [vehicle]);

  useEffect(() => {
    void fetchVehicleGatewayList();
  }, []);

  const onCardClicked: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const index = Number(event.currentTarget.dataset.index);
      const gw = vehicleGateway!![index];
      navigate("/dashboard/gateways/" + gw.id + "?fromVehicle=true");
    },
    [navigate, vehicleGateway]
  );

  return (
    <ResponsiveContainer>
      <AppLoader loading={!!fetchListState?.loading} />
      <Typography.Text type={"secondary"}>
        {vehicleGateway?.length} gateways en este vehículo.
      </Typography.Text>

      <Button.Group className={"block my-2"}>
        <Button
          type={"default"}
          onClick={fetchVehicleGatewayList}
          icon={<FontAwesomeIcon icon={faRefresh} />}
        >
          Actualizar
        </Button>
      </Button.Group>
      <div className={"overflow-y-auto flex flex-row flex-wrap gap-2"}>
        {!!vehicleGateway && vehicleGateway.length > 0 ? (
          vehicleGateway.map((gateway, index) => (
            <Card
              onClick={onCardClicked}
              data-index={index}
              key={`gateway-${index}`}
            >
              <div
                className={"flex flex-row items-center justify-center gap-2"}
              >
                <FontAwesomeIcon
                  icon={faServer}
                  className="bg-secondary text-secondary-text rounded-full p-2"
                />
                <Typography.Text type={"secondary"} strong>
                  {gateway.description}
                </Typography.Text>
                <Typography.Text type={"secondary"}>
                  {gateway.key}
                </Typography.Text>
                {/* {gateway.variables && gateway.variables.length > 0 ? (
                  <div className={"flex flex-col gap-3"}>
                    <Typography.Text strong>
                      Variables Disponibles
                    </Typography.Text>
                    <div className={"flex flex-row flex-wrap gap-2"}>
                      {gateway.variables.map((variable, i) => {
                        return (
                          <Card key={`gw-var-${i}`} className={""}>
                            <div className={"flex flex-col justify-center"}>
                              <Typography.Text>
                                {variable.Chart.description}
                              </Typography.Text>
                              <div className={"flex flex-row gap-2"}>
                                <Typography.Text type={"secondary"}>
                                  {variable.Device.description}
                                </Typography.Text>
                              </div>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <Typography.Text type={"secondary"}>
                    No hay variables disponibles en este gateway.
                  </Typography.Text>
                )} */}
              </div>
            </Card>
          ))
        ) : (
          <AppEmptyContentStateComponent
            description={"No hay gateways en el vehículo."}
          />
        )}
      </div>
    </ResponsiveContainer>
  );
};
