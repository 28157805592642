import { FC, PropsWithChildren, createContext, useState } from "react";
import { Customer } from "../../features/customer/domain/type/Customer";

type Value = {
  customer: Customer | null;
  setCustomer: (customer: Customer) => void;
};

export const AppCustomerContext = createContext<Value>({
  customer: null,
  setCustomer: null!,
});

export const AppCustomerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  return (
    <AppCustomerContext.Provider
      value={{
        customer,
        setCustomer,
      }}
    >
      {children}
    </AppCustomerContext.Provider>
  );
};
