import { useState } from "react";
import {
  GatewayAction,
  GatewayActionList,
} from "../domain/type/GatewayActions";
import { GatewayActionsRepository } from "../data/repository/GatewayAction";
import { AppTaskState } from "../../../app/domain/type/State";
import { AppTaskStateG } from "../../../app/domain/model/State";

export function useGatewayActionViewModel() {
  const [fetchListState, setFetchListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [gatewayActionList, setGatewayActionList] =
    useState<GatewayActionList | null>(null);
  const [requestActionState, setRequestActionState] =
    useState<AppTaskState<boolean> | null>(null);
  const [toRequestAction, setToRequestAction] = useState<GatewayAction | null>(
    null
  );
  const [actionExecutionState, setActionExecutionState] =
    useState<AppTaskState<true> | null>();
  const [actionResponseTokenId, setActionResponseTokenId] = useState<
    string | null
  >(null);
  async function fetchList(id: number) {
    if (fetchListState?.loading) return;
    setFetchListState(AppTaskStateG.loading());
    try {
      const list = await GatewayActionsRepository.getGatewayActions(id);
      if (list.ok) {
        setGatewayActionList(list.data!!);
        setFetchListState(AppTaskStateG.success(true));
      } else setFetchListState(AppTaskStateG.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchListState(AppTaskStateG.error(error));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  async function requestAction({ password }: { password: string }) {
    if (requestActionState?.loading) return;
    const to = toRequestAction;
    if (!to) {
      setRequestActionState(
        AppTaskStateG.error(new Error("No se ha seleccionado ninguna acción."))
      );
      return;
    }
    setRequestActionState(AppTaskStateG.loading());
    try {
      const response = await GatewayActionsRepository.requestAction({
        password,
        actionId: to.id,
      });
      setActionResponseTokenId(response.data!!.token_id);
      setRequestActionState(AppTaskStateG.success(true));
      return true;
    } catch (e: any) {
      setActionResponseTokenId(null);
      setRequestActionState(AppTaskStateG.error(e));
    }
  }

  async function requestScan(gatewayKey: string) {
    if (requestActionState?.loading) return;
    setRequestActionState(AppTaskStateG.loading());
    try {
      const response = await GatewayActionsRepository.requestScanAction({
        gatewayKey,
      });
      setActionResponseTokenId(response.data!!.token_id);
      setRequestActionState(AppTaskStateG.success(true));
      return true;
    } catch (e: any) {
      setActionResponseTokenId(null);
      setRequestActionState(AppTaskStateG.error(e));
    }
  }

  function onRequestActionStateReceived() {
    setRequestActionState(null);
  }

  function selectAction(action: GatewayAction) {
    setToRequestAction(action);
  }

  function onActionSelectCompleted() {
    setToRequestAction(null);
  }

  function startExecutionState() {
    setActionExecutionState(AppTaskStateG.loading());
  }

  function actionExecutionFailed(message?: string) {
    setActionExecutionState(AppTaskStateG.error(new Error(message)));
  }

  function actionExecutionSuccess() {
    setActionExecutionState(AppTaskStateG.success(true));
  }

  function onActionExecutionStateReceived() {
    setActionExecutionState(null);
  }

  return {
    fetchListState,
    fetchList,
    onFetchListStateReceived,
    gatewayActionList,
    requestAction,
    onRequestActionStateReceived,
    requestActionState,
    toRequestAction,
    selectAction,
    onActionSelectCompleted,
    actionExecutionState,
    startExecutionState,
    actionExecutionFailed,
    actionExecutionSuccess,
    onActionExecutionStateReceived,
    requestScan,
    actionResponseTokenId,
  };
}
