import { FC } from "react";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faAt,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import { Form, FormItem, Input, SubmitButton } from "formik-antd";
import { Button } from "antd";
import { PasswordRecoveryRequestData } from "../../../auth/domain/type/Login";

type Props = {
  onSubmit: (
    values: PasswordRecoveryRequestData
  ) => Promise<boolean | undefined>;
  onBackPressed: () => void;
};

const kRECOVERY_SCHEMA = yup.object({
  email: yup
    .string()
    .required("Ingresa un email")
    .email("Ingresa un email válido"),
});

export const PasswordRecoveryPageForm: FC<Props> = ({
  onSubmit,
  onBackPressed,
}) => {
  return (
    <Formik<PasswordRecoveryRequestData>
      initialValues={{} as any}
      onSubmit={(values, helpers) => {
        onSubmit(values).then((it) => {
          if (it) helpers.resetForm();
          helpers.setSubmitting(false);
        });
      }}
      validationSchema={kRECOVERY_SCHEMA}
    >
      <Form className={"flex flex-col w-full"}>
        <FormItem name={"email"}>
          <Input
            required
            name="email"
            autoFocus
            prefix={<FontAwesomeIcon icon={faAt} />}
          />
        </FormItem>
        <Button.Group className="ml-auto">
          <Button
            type="default"
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={onBackPressed}
          >
            Atrás
          </Button>
          <SubmitButton icon={<FontAwesomeIcon icon={faPaperPlane} />}>
            Enviar
          </SubmitButton>
        </Button.Group>
      </Form>
    </Formik>
  );
};
