import {
  useState,
  DetailedHTMLProps,
  ImgHTMLAttributes,
  FC,
  ReactElement,
} from "react";
import Zoom from "react-medium-image-zoom";

type Props = {
  errorComponent: ReactElement;
  imgProps: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
};

export const ImageHOC: FC<Props> = ({ errorComponent, imgProps }) => {
  const [error, setError] = useState(false);
  const handleImageError = () => {
    setError(true);
  };

  return (
    <>
      {error ? (
        errorComponent
      ) : (
        <Zoom>
          <img {...imgProps} onError={handleImageError} />
        </Zoom>
      )}
    </>
  );
};
