import {
  GatewayActionRequest,
  GatewayScanRequest,
} from "../../domain/type/GatewayActions";
import { RemoteGatewayActionSource } from "../source/ActionRemote";

export class GatewayActionsRepository {
  static async getGatewayActions(gatewayId: number) {
    return await RemoteGatewayActionSource.getGatewayActions(gatewayId);
  }

  static async requestAction(request: GatewayActionRequest) {
    return await RemoteGatewayActionSource.requestAction(request);
  }

  static async requestScanAction(request: GatewayScanRequest) {
    return await RemoteGatewayActionSource.requestScanAction(request);
  }
}
