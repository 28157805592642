import { useState } from "react";
import { AuthRepository } from "../../auth/data/repository/Auth";
import { AppTaskState } from "../../../app/domain/type/State";
import {
  PasswordRecoveryProceedData,
  PasswordRecoveryValidationData,
} from "../../auth/domain/type/Login";
import { AppTaskStateG } from "../../../app/domain/model/State";

export function usePasswordResetViewModel() {
  const [validationState, setValidationState] =
    useState<AppTaskState<boolean> | null>(null);

  const [proceedChangeState, setProceedChangeState] =
    useState<AppTaskState<boolean> | null>(null);
  async function validateToken(data: PasswordRecoveryValidationData) {
    if (validationState?.loading) return;
    setValidationState(AppTaskStateG.loading());
    try {
      await AuthRepository.validatePasswordRecoveryToken(data);
      setValidationState(AppTaskStateG.success(true));
    } catch (e: any) {
      setValidationState(AppTaskStateG.error(e));
    }
  }

  function onValidationStateReceived() {
    setValidationState(null);
  }

  async function proceedPasswordReset(data: PasswordRecoveryProceedData) {
    setProceedChangeState(AppTaskStateG.loading());
    try {
      await AuthRepository.proceedPasswordRecovery(data);
      setProceedChangeState(AppTaskStateG.success(true));
    } catch (e: any) {
      setProceedChangeState(AppTaskStateG.error(e));
    }
  }

  function onProceedChangeStateReceived() {
    setProceedChangeState(null);
  }

  return {
    validationState,
    proceedChangeState,
    validateToken,
    onValidationStateReceived,
    proceedPasswordReset,
    onProceedChangeStateReceived,
  };
}
