import { DashboardPathsType } from "../type/Paths";

export const DashboardPaths: DashboardPathsType = {
  dashboard: "/dashboard",
  overview: "/dashboard/overview",
  vehicle_list: "/dashboard/vehicles",
  vehicle_p_id: "/dashboard/vehicles/:id",
  gateway_list: "/dashboard/gateways",
  gateway_p_id: "/dashboard/gateways/:id",
  customer: "/dashboard/customer",
  user_list: "/dashboard/users",
  user_p_id: "/dashboard/users/:id",
};
