import { FC, useCallback, useEffect } from "react";
import { Button, notification } from "antd";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useGatewayViewModel } from "./ViewModel";
import { useDashboardHeader } from "../../../core/layout/dashboard/presentation/hook/Header";
import { Gateway } from "../../gateway/domain/type/Gateway";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";
import { GatewayListData } from "./component/Data";

export const GatewayListPage: FC = () => {
  const { setTitle } = useDashboardHeader();
  const { fetchList, gatewayList, fetchListState, onFetchListStateReceived } =
    useGatewayViewModel();

  const navigate = useNavigate();

  const onItemClicked = useCallback(
    (record: Gateway) => {
      navigate("/dashboard/gateways/" + record.id);
    },
    [navigate, gatewayList]
  );

  useEffect(() => {
    void fetchList();
    setTitle("Gateways");
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState?.hasError) {
        notification.error({
          message: "Gateways",
          description: fetchListState?.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader loading={!!fetchListState && fetchListState.loading} />
      <CustomBreadCrumb
        items={[
          {
            pathname: "...",
            active: true,
          },
        ]}
      />
      <ContentHeader
        title={"Gateways"}
        subtitle={`${gatewayList?.length} gateways`}
        extra={
          <Button.Group>
            <Button
              type={"primary"}
              ghost
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchList}
            >
              Actualizar
            </Button>
          </Button.Group>
        }
      />
      <GatewayListData data={gatewayList} onItemClicked={onItemClicked} />
    </ResponsiveContainer>
  );
};
