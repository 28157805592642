import { FC, useCallback, useEffect, useState } from "react";
import { DashboardHeaderUseCase } from "../../domain/usecase/Header";
import { Typography } from "antd";

export const DashboardTimeLabel: FC = () => {
  const [currentTime, setCurrentTime] = useState<string>("");
  const timeCallback = useCallback(() => {
    setCurrentTime(DashboardHeaderUseCase.getCurrentTime());
  }, [setCurrentTime]);
  useEffect(() => {
    const id = setInterval(timeCallback, 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <Typography.Text
      style={{
        padding: 0,
        margin: 0,
      }}
      className="leading-tight"
      type="secondary"
    >
      {currentTime}
    </Typography.Text>
  );
};
