import { FC } from "react";
import { BaseLinearChart } from "./BaseLinear";
import { ChartData1, ChartSchemaDefinition } from "../../../domain/type/Chart";

type Props = {
  tooltipTitle: string;
  items: Array<ChartData1<number>>;

  id: string;
  schema: ChartSchemaDefinition;
};

export const AreaChart: FC<Props> = (props) => {
  return <BaseLinearChart {...props} type={"area"} />;
};
