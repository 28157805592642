import { FC, PropsWithChildren } from "react";
import { Divider, Typography } from "antd";
import { useLottie } from "lottie-react";
import errorAnimationData from "../../../assets/animation/animation_error.json";
type Props = PropsWithChildren<{
  title?: string;
  description: string;
  animation?: unknown;
}>;
export const AppStateComponent: FC<Props> = ({
  description,
  children,
  title,
  animation,
}) => {
  const { View } = useLottie({
    loop: true,
    animationData: animation,
    style: {
      width: 200,
      height: 200,
    },
  });
  return (
    <div className={"flex flex-col justify-center items-center"}>
      {View}
      <Typography.Title className="font-quicksand" level={4}>
        {title}
      </Typography.Title>
      <Typography.Text type={"secondary"}>{description}</Typography.Text>
      {children && <div className={"mt-4"}>{children}</div>}
      <Divider className={"mb-0 pb-0"} />
    </div>
  );
};

export const AppErrorStateComponent: FC<Props> = (props) => (
  <AppStateComponent {...props} animation={errorAnimationData} />
);

export const AppEmptyContentStateComponent: FC<Props> = (props) => (
  <AppStateComponent {...props} animation={errorAnimationData} />
);
