import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { CustomerUser } from "../../../customer_user/domain/type/CustomerUser";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import { ApiService } from "../../../../app/service/RemoteClient";
import {
  LoginData,
  LoginResponseData,
  PasswordRecoveryProceedData,
  PasswordRecoveryRequestData,
  PasswordRecoveryValidationData,
  PostLoginData,
} from "../../domain/type/Login";

export class RemoteAuthSource extends BaseRemoteSource {
  static async login(
    data: LoginData
  ): Promise<RemoteSourceResponse<LoginResponseData> | undefined> {
    try {
      const loginResult = await ApiService.postWithAuth<LoginData>(
        "/auth/customer_user/login",
        data
      );
      this.checkResponseCredentials(loginResult);
      return loginResult.data as RemoteSourceResponse<LoginResponseData>;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async getCurrentUser(): Promise<RemoteSourceResponse | undefined> {
    try {
      const currentUserResult = await ApiService.getWithAuth(
        "/customer_user/get-current-user"
      );
      return currentUserResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async logout(): Promise<RemoteSourceResponse | undefined> {
    try {
      const logoutResult = await ApiService.postWithAuth(
        "/auth/customer_user/logout"
      );
      return logoutResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async postLogin(
    data: PostLoginData
  ): Promise<RemoteSourceResponse | undefined> {
    try {
      const postLoginResult = await ApiService.post<PostLoginData>(
        "/auth/customer_user/postlogin",
        data
      );
      this.checkResponseCredentials(postLoginResult);
      return postLoginResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async sendPasswordRecoveryRequest(
    data: PasswordRecoveryRequestData
  ): Promise<RemoteSourceResponse | undefined> {
    try {
      const passwordRequestResult =
        await ApiService.post<PasswordRecoveryRequestData>(
          "/auth/customer_user/password-recovery-request",
          data
        );
      return passwordRequestResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async validatePasswordRecoveryToken(
    data: PasswordRecoveryValidationData
  ): Promise<RemoteSourceResponse | undefined> {
    try {
      const validationResult =
        await ApiService.post<PasswordRecoveryValidationData>(
          "/auth/customer_user/password-recovery-validation",
          data
        );
      return validationResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async proceedPasswordRecovery(
    data: PasswordRecoveryProceedData
  ): Promise<RemoteSourceResponse | undefined> {
    try {
      const resetResult = await ApiService.post<PasswordRecoveryProceedData>(
        "/auth/customer_user/password-recovery-proceed",
        data
      );
      return resetResult.data as RemoteSourceResponse;
    } catch (e) {
      this.parseError(e);
    }
  }

  static async getSession(): Promise<
    RemoteSourceResponse<CustomerUser> | undefined
  > {
    try {
      const sessionResult = await ApiService.postWithAuth(
        "/auth/customer_user/get-session"
      );
      this.checkResponseCredentials(sessionResult);
      return sessionResult.data as RemoteSourceResponse<CustomerUser>;
    } catch (e) {
      this.parseError(e);
    }
  }
}
