import { FC } from "react";
import { GatewayListTable } from "./Table";
import { GatewayListGrid } from "./Grid";
import { Gateway } from "../../../gateway/domain/type/Gateway";
import { useAppResponsiveScreen } from "../../../../app/hook/Breakpoint";
import { AppEmptyContentStateComponent } from "../../../../core/presentation/component/State";

export type GatewayListDataProps = {
  data?: Gateway[] | null;
  onItemClicked: (item: Gateway) => void;
};
export const GatewayListData: FC<GatewayListDataProps> = (props) => {
  const { isMobileScreen } = useAppResponsiveScreen();

  return props.data && props.data.length > 0 ? (
    isMobileScreen ? (
      <GatewayListGrid {...props} />
    ) : (
      <GatewayListTable {...props} />
    )
  ) : (
    <AppEmptyContentStateComponent
      title={"Sin gateways"}
      description={"No hay gateways registrados..."}
    />
  );
};
