import {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Checkbox,
  Form,
  notification,
  Radio,
  RadioChangeEvent,
  Typography,
} from "antd";
import { DashboardChartRenderer } from "./components/ChartRenderer";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { GatewayDevice } from "../../../../../gateway/domain/type/GatewayDevice";
import { useGatewayDeviceViewModel } from "../../../../../gateway/presentation/GatewayDeviceViewModel";
import { useGatewayViewModel } from "../../../../../gateway_list/presentation/ViewModel";
import { TabContentBox } from "../../../../../../core/presentation/component/TabContentBox";
import { AppLoader } from "../../../../../../core/presentation/component/AppLoader";
import {
  AppEmptyContentStateComponent,
  AppStateComponent,
} from "../../../../../../core/presentation/component/State";
import { FullscreenToggle } from "../../../../../../core/presentation/component/Fullscreen";
import { SocketIOService } from "../../../../../../app/service/SocketIO";
import { useGatewayActionViewModel } from "../../../../../gateway/presentation/GatewayActionViewModel";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointer, faSearch } from "@fortawesome/free-solid-svg-icons";
import { GatewayActionResponse } from "../../../../../gateway/domain/type/GatewayActions";
import ReactGridLayout from "react-grid-layout";
import { ChartLayoutUseCase } from "../../../../domain/usecase/ChartLayout";
import { useComponentSize } from "../../../../../../app/hook/Size";
import animationData from "../../../../../../assets/animation/animation_d1.json";
import { ImageHOC } from "../../../../../../core/presentation/component/Image";
type Props = {
  vehicle: Vehicle;
};
export const VehicleDeviceTab: FC<Props> = ({ vehicle }) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { ref: c1Ref, width: c1Width } = useComponentSize();
  const [selectedGwKey, setSelectedGwKey] = useState<string | null>(null);
  const [selectedDevicesKeys, setSelectedDevicesKeys] = useState<
    Array<string> | undefined
  >();
  const [selectedDevices, setSelectedDevices] =
    useState<Array<GatewayDevice> | null>();
  const [availableDevices, setAvailableDevices] =
    useState<Array<GatewayDevice> | null>();
  const {
    fetchList,
    onFetchListStateReceived,
    gatewayDeviceList,
    fetchListState,
    fetchPicture,
    fetchPictureState,
    gatewayDevicePicture,
    onFetchPictureStateReceived,
  } = useGatewayDeviceViewModel();
  const {
    fetchVehicleList,
    vehicleGateway,
    fetchListState: fetchVehicleListState,
    onFetchListStateReceived: onFetchVehicleListStateReceived,
  } = useGatewayViewModel();
  const divRef = useRef<HTMLDivElement>(null);

  const selectedGateway = useMemo(() => {
    if (!!selectedGwKey && !!vehicleGateway) {
      return vehicleGateway.find((it) => it.key === selectedGwKey);
    }
  }, [selectedGwKey, vehicleGateway]);

  useEffect(() => {
    if (!!selectedGateway) {
      void fetchList(selectedGateway.id, {
        dashboard: true,
      });
      void fetchPicture(selectedGateway.key);
    }
  }, [selectedGateway]);

  useEffect(() => {
    if (!!selectedGateway)
      void fetchList(selectedGateway.id, {
        dashboard: true,
      });
    void fetchVehicleList(vehicle.id);
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Error al obtener los dispositivos.",
          description: fetchListState.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!fetchPictureState && !fetchPictureState.loading) {
      if (fetchPictureState.hasError) {
        notification.error({
          message: "Error al obtener la imagen del dispositivo.",
          description: fetchPictureState.error?.message,
        });
      }
      onFetchPictureStateReceived();
    }
  }, [fetchPictureState]);

  useEffect(() => {
    if (!!fetchVehicleListState && !fetchVehicleListState.loading) {
      if (fetchVehicleListState.hasError) {
        notification.error({
          message: "Error al obtener el gateway.",
          description: fetchVehicleListState.error?.message,
        });
      } else {
        if (!!vehicleGateway && vehicleGateway?.length > 0) {
          setSelectedGwKey(vehicleGateway[0].key);
        }
      }
      onFetchVehicleListStateReceived();
    }
  }, [fetchVehicleListState]);

  const {
    requestScan,
    onRequestActionStateReceived,
    requestActionState,
    actionExecutionFailed,
    actionExecutionState,
    actionExecutionSuccess,
    onActionExecutionStateReceived,
    startExecutionState,
    actionResponseTokenId,
  } = useGatewayActionViewModel();

  useEffect(() => {
    if (!!requestActionState && !requestActionState.loading) {
      if (requestActionState.hasError) {
        notification.error({
          description: requestActionState.error?.message,
          message: "Solicitud de comando",
        });
      } else {
        startExecutionState();
      }
      onRequestActionStateReceived();
    }
  }, [requestActionState]);

  useEffect(() => {
    if (!!actionExecutionState && !actionExecutionState.loading) {
      if (actionExecutionState.hasError) {
        notification.error({
          description: actionExecutionState.error?.message,
          message: "Escaneo de Sensores",
        });
      } else {
        notification.success({
          message: "Escaneo completo",
        });
      }
      onActionExecutionStateReceived();
      onRequestActionStateReceived();
    }
  }, [actionExecutionState]);

  const onButtonClicked: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      requestScan(selectedGwKey!!);
      timeoutRef.current = setTimeout(() => {
        actionExecutionFailed("Tiempo de espera agotado...");
      }, 10000);
    }, [requestScan, selectedGwKey, timeoutRef, actionExecutionFailed]);

  const onSelectAllButtonClicked: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      setAvailableDevices(gatewayDeviceList);
      setSelectedDevicesKeys(undefined);
    }, [gatewayDeviceList, setAvailableDevices]);

  const actionsListener = useCallback(
    (data: GatewayActionResponse<Array<any>>) => {
      try {
        const action = data.gateway.scan;
        if (action.ok) {
          const available = [];
          const actionData = action.data!!;
          for (let key in actionData) {
            const devSerial = actionData[key].devSerial;
            if (!!devSerial) {
              const gwDevice = gatewayDeviceList?.find(
                (it) => it.Device.key === devSerial
              );
              if (!!gwDevice) {
                available.push(gwDevice);
              }
            }
          }
          setAvailableDevices(available);
          actionExecutionSuccess();
        } else {
          actionExecutionFailed(action.message);
        }
      } catch (e) {
        actionExecutionFailed("Respuesta no válida.");
      }
      if (!!timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [
      actionExecutionSuccess,
      actionExecutionFailed,
      timeoutRef,
      setAvailableDevices,
      gatewayDeviceList,
      actionResponseTokenId,
    ]
  );

  useEffect(() => {
    const tokenId = actionResponseTokenId;
    SocketIOService.socketOn(`actions-response/${tokenId}`, actionsListener);
    return () => {
      SocketIOService.socketOff(`actions-response/${tokenId}`, actionsListener);
    };
  }, [actionResponseTokenId]);

  const onGwSelected = useCallback(
    (e: RadioChangeEvent) => {
      setAvailableDevices(null);
      setSelectedDevicesKeys(undefined);
      setSelectedGwKey(e.target.value);
    },
    [setSelectedGwKey, setAvailableDevices, setSelectedDevicesKeys]
  );

  const onDevicesSelected = useCallback(
    (value: CheckboxValueType[]) => {
      setSelectedDevicesKeys(value as any);
    },
    [setSelectedDevicesKeys]
  );

  useEffect(() => {
    if (!!selectedDevicesKeys) {
      const available = [];
      for (let key of selectedDevicesKeys) {
        const gwDevice = availableDevices?.find((it) => it.Device.key === key);
        if (!!gwDevice) {
          available.push(gwDevice);
        }
      }
      setSelectedDevices(available);
    } else {
      setSelectedDevices(null);
    }
  }, [selectedDevicesKeys, availableDevices, setSelectedDevices]);

  const layout = useMemo(() => {
    if (!!selectedDevices && !!c1Width) {
      return ChartLayoutUseCase.generateLayout(c1Width, selectedDevices);
    }
  }, [selectedDevices, c1Width]);

  return (
    <TabContentBox>
      <AppLoader
        loading={
          (!!fetchListState && fetchListState.loading) ||
          (!!fetchVehicleListState && fetchVehicleListState.loading) ||
          (!!requestActionState && requestActionState.loading) ||
          (!!actionExecutionState && actionExecutionState.loading)
        }
      />
      {!vehicleGateway || vehicleGateway.length === 0 ? (
        <AppEmptyContentStateComponent
          description={"El vehículo no está vinculado a ningún gateway."}
        />
      ) : (
        <div
          className={
            "w-full h-fit gap-2 flex flex-col relative overflow-x-hidden overflow-y-scroll pt-2"
          }
          ref={divRef}
        >
          <Typography.Text type={"secondary"}>
            Seleccionar variables para monitorear
          </Typography.Text>
          <div className="flex flex-col md:flex-row gap-2 w-full">
            <Card className="h-fit flex-1">
              <Form layout={"vertical"}>
                <Form.Item label={"Gateway"}>
                  <Radio.Group onChange={onGwSelected} value={selectedGwKey}>
                    {vehicleGateway?.map((it, index) => {
                      return (
                        <Radio key={`gw-${it.key}`} value={it.key}>
                          {it.key}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>
                {!!selectedGateway ? (
                  <Form.Item>
                    <Button.Group>
                      <Button
                        onClick={onButtonClicked}
                        type="primary"
                        icon={<FontAwesomeIcon icon={faSearch} />}
                        shape="round"
                      >
                        Escanear Variables
                      </Button>
                      <Button
                        onClick={onSelectAllButtonClicked}
                        type="default"
                        icon={<FontAwesomeIcon icon={faHandPointer} />}
                        shape="round"
                      >
                        Todas las Variables
                      </Button>
                    </Button.Group>
                  </Form.Item>
                ) : null}
                {!!availableDevices && availableDevices?.length === 0 && (
                  <Form.Item label={"Variables"}>
                    <Typography.Text type={"secondary"}>
                      No hay variables disponibles para este gateway.
                    </Typography.Text>
                  </Form.Item>
                )}
                {!!availableDevices && availableDevices.length > 0 && (
                  <Form.Item label={"Variables"}>
                    <Checkbox.Group
                      value={selectedDevicesKeys}
                      onChange={onDevicesSelected}
                    >
                      {availableDevices?.map((it) => {
                        return (
                          <Checkbox
                            key={`cb-${it.deviceId}`}
                            value={it.Device.key}
                          >
                            {it.Device.description}
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  </Form.Item>
                )}
              </Form>
            </Card>
            {!!gatewayDevicePicture && (
              <ImageHOC
                imgProps={{
                  src: `https://iot-admin.acmecia.com/customers/${gatewayDevicePicture.path}`,
                  width: "100%",
                  className: "w-full md:w-64 object-contain",
                }}
                errorComponent={<span />}
              />
            )}
          </div>

          {selectedGateway &&
          gatewayDeviceList &&
          selectedDevicesKeys &&
          selectedDevicesKeys?.length > 0 ? (
            <div
              className={
                "overflow-x-visible w-full flex-wrap flex flex-row justify-stretch gap-2"
              }
              ref={c1Ref}
            >
              <FullscreenToggle
                containerRef={divRef}
                className={"absolute right-2 top-0"}
              />

              {!!layout && (
                <ReactGridLayout
                  layout={layout.layout}
                  cols={layout.cols}
                  width={c1Width}
                  rowHeight={layout.rowHeight}
                  className="layout"
                >
                  {layout.layout.map((it, idx) => {
                    const currentDevice = selectedDevices!![idx];
                    return (
                      <div key={it.i} className="w-full h-full overflow-hidden">
                        <DashboardChartRenderer
                          gateway={selectedGateway!!}
                          device={currentDevice.Device}
                          chart={currentDevice.Chart}
                        />
                      </div>
                    );
                  })}
                </ReactGridLayout>
              )}
            </div>
          ) : (
            <AppStateComponent
              description={"Selecciona variables para visualizar."}
              title={"Selecciona las variables"}
              animation={animationData}
            />
          )}
        </div>
      )}
    </TabContentBox>
  );
};
