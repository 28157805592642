import { ThemeConfig } from "antd/lib";
import { AppAntThemeGenerator } from "../model/ThemeGenerator";
import { darken, lighten, readableColorIsBlack, transparentize } from "color2k";
import { AppThemeUseCase } from "../usecase/Theme";
import { theme } from "antd";
export class PrimaryMenuThemeGenerator extends AppAntThemeGenerator {
  build(): ThemeConfig {
    const {
      colorPrimary,
      textColorOnSecondary,
      textColorOnPrimary,
      colorSecondary,
      textColorOnTertiary,
      colorTertiary,
    } = this.palette;
    const itemColor = transparentize(
      AppThemeUseCase.redableColorIsDark(colorTertiary)
        ? lighten(textColorOnTertiary, 0.05)
        : darken(textColorOnTertiary, 0.05),
      0.1
    );
    return {
      token: {
        fontFamily: "'Quicksand', sans-serif",
        colorPrimary: colorPrimary,
        colorBorderSecondary: "transparent",
        colorBorder: "transparent",
        colorBgBase: "transparent",
      },
      algorithm: theme.compactAlgorithm,
      components: {
        Menu: {
          colorBgContainer: "transparent",
          colorBgBase: "transparent",
          itemColor,
          darkItemColor: textColorOnSecondary,
          itemSelectedBg: colorPrimary,
          darkItemSelectedBg: colorPrimary,
          itemSelectedColor: textColorOnPrimary,
          darkItemSelectedColor: textColorOnPrimary,
          itemHoverBg: textColorOnSecondary,
          darkItemHoverBg: textColorOnSecondary,
          itemHoverColor: colorSecondary,
          darkItemHoverColor: colorSecondary,
          fontFamily: "'Quicksand', sans-serif",
        },
      },
    };
  }
}
