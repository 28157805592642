import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Card, Divider, notification, Radio, Typography } from "antd";
import dayjs from "dayjs";
import { Formik, FormikHelpers } from "formik";
import {
  Form,
  Checkbox,
  FormItem,
  SubmitButton,
  DatePicker,
} from "formik-antd";
import * as Yup from "yup";
import { DataViewer } from "./DataViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { useDeviceDataViewModel } from "../../../../../../core/presentation/viewmodel/DeviceData";
import { Gateway } from "../../../../../gateway/domain/type/Gateway";
import { useGatewayDeviceViewModel } from "../../../../../gateway/presentation/GatewayDeviceViewModel";
import { useGatewayViewModel } from "../../../../../gateway_list/presentation/ViewModel";
import { ResponsiveContainer } from "../../../../../../core/presentation/component/Container";
import { AppEmptyContentStateComponent } from "../../../../../../core/presentation/component/State";
import { FullscreenToggle } from "../../../../../../core/presentation/component/Fullscreen";
import { AppLoader } from "../../../../../../core/presentation/component/AppLoader";

type Props = {
  vehicle: Vehicle;
};

type FetchData = {
  devices: string[];
  dateRange: [Date, Date];
};

const kREPORT_FORM_VALIDATION = Yup.object({
  devices: Yup.array(Yup.string())
    .min(1, "Selecciona al menos 1 variable.")
    .required(),
  dateRange: Yup.array(Yup.string())
    .length(2, "Selecciona el rango de fecha.")
    .required("Selecciona el rango de fecha."),
});

export const VehicleDeviceHistoryTab: FC<Props> = ({ vehicle }) => {
  const { fetchState, fetchDeviceData, deviceData, onFetchStateReceived } =
    useDeviceDataViewModel();
  const [selectedGw, setSelectedGw] = useState<Gateway | null>(null);
  const [dataRange, setDataRange] = useState<any>();
  const {
    fetchList,
    onFetchListStateReceived,
    gatewayDeviceList: deviceList,
    fetchListState,
  } = useGatewayDeviceViewModel();
  const {
    fetchVehicleList,
    fetchGatewayState,
    onFetchGatewayStateReceived,
    vehicleGateway,
    fetchListState: fetchVehicleListState,
    onFetchListStateReceived: onFetchVehicleListStateReceived,
  } = useGatewayViewModel();

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!selectedGw)
      void fetchList(selectedGw.id, {
        historic: true,
      });
  }, [selectedGw]);

  useEffect(() => {
    void fetchVehicleList(vehicle.id);
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Error al obtener los dispositivos.",
          description: fetchListState.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!fetchVehicleListState && !fetchVehicleListState.loading) {
      if (fetchVehicleListState.hasError) {
        notification.error({
          message: "Error al obtener el gateway.",
          description: fetchVehicleListState.error?.message,
        });
      } else {
        if (!!vehicleGateway && vehicleGateway.length > 0) {
          setSelectedGw(vehicleGateway[0]);
        }
      }
      onFetchVehicleListStateReceived();
    }
  }, [fetchVehicleListState]);

  useEffect(() => {
    if (!!fetchState && !fetchState.loading) {
      if (fetchState.hasError) {
        notification.error({
          message: "Error al obtener los datos.",
          description: fetchState.error?.message,
        });
        console.log(fetchState.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  const fetchData = useCallback(
    (formData: FetchData, helpers: FormikHelpers<any>) => {
      setDataRange(formData.dateRange);
      fetchDeviceData(
        selectedGw!!.key,
        formData.devices,
        formData.dateRange[0],
        formData.dateRange[1]
      ).then((_) => {
        helpers.setSubmitting(false);
      });
    },
    [fetchDeviceData]
  );

  return (
    <ResponsiveContainer>
      <AppLoader
        loading={
          (!!fetchVehicleListState && fetchVehicleListState.loading) ||
          (!!fetchGatewayState && fetchGatewayState.loading) ||
          (!!fetchState && fetchState.loading)
        }
      />

      {!vehicleGateway || vehicleGateway.length === 0 ? (
        <AppEmptyContentStateComponent
          description={"El vehículo no está vinculado a ningún gateway."}
        />
      ) : (
        <div
          ref={divRef}
          className={
            "w-full h-fit gap-2 relative overflow-x-hidden overflow-y-auto"
          }
        >
          <FullscreenToggle
            containerRef={divRef}
            className={"absolute top-2 right-2 z-50"}
          />
          <Card>
            <div className={"flex flex-col gap-2"}>
              <Typography.Text type={"secondary"}>
                Seleccionar gateway para obtener variables
              </Typography.Text>
              <Radio.Group
                onChange={(selection) => setSelectedGw(selection.target.value)}
                value={selectedGw}
              >
                {vehicleGateway?.map((it, index) => {
                  return (
                    <Radio key={`gw-${it.key}`} value={it}>
                      {it.key}
                    </Radio>
                  );
                })}
              </Radio.Group>
              <Formik<FetchData>
                initialValues={{} as any}
                onSubmit={fetchData}
                validationSchema={kREPORT_FORM_VALIDATION}
              >
                <Form layout={"vertical"}>
                  <FormItem name={"devices"} label={"Variables"}>
                    <Checkbox.Group
                      name={"devices"}
                      options={deviceList?.map((it) => ({
                        label: it.Device.description,
                        value: it.Device.key,
                      }))}
                    />
                  </FormItem>
                  <FormItem name={"dateRange"} label={"Periodo"}>
                    <DatePicker.RangePicker
                      name={"dateRange"}
                      presets={[
                        {
                          label: "Hoy",
                          value: [dayjs().startOf("day"), dayjs().endOf("day")],
                        },
                        {
                          label: "Esta semana",
                          value: [dayjs().add(-7, "d"), dayjs()],
                        },
                        {
                          label: "Últimas 2 semanas",
                          value: [dayjs().add(-14, "d"), dayjs()],
                        },
                        {
                          label: "Este mes",
                          value: [dayjs().add(-30, "d"), dayjs()],
                        },
                        {
                          label: "Últimos 3 meses",
                          value: [dayjs().add(-90, "d"), dayjs()],
                        },
                      ]}
                    />
                  </FormItem>
                  <SubmitButton icon={<FontAwesomeIcon icon={faEye} />}>
                    Visualizar
                  </SubmitButton>
                </Form>
              </Formik>
            </div>
          </Card>

          <Divider />
          {deviceData ? (
            <div className={"w-full overflow-x-hidden pt-2 relative"}>
              <DataViewer deviceData={deviceData} />
            </div>
          ) : (
            <AppEmptyContentStateComponent
              description={"Seleccionar gateway y variables para visualizar"}
              title={"En espera..."}
            />
          )}
        </div>
      )}
    </ResponsiveContainer>
  );
};
