import { ChartSchemaDefinition } from "../../../../core/domain/type/Chart";
import { Layout } from "react-grid-layout";
import { GatewayDevice } from "../../../gateway/domain/type/GatewayDevice";
const kGAUGE_MIN_WIDTH = 350;
const kMIN_COL_WIDTH = 50;

export class ChartLayoutUseCase {
  constructor() {}
  static generateLayout(availableSpace: number, schemas: GatewayDevice[]) {
    let cols = Math.floor(availableSpace / kMIN_COL_WIDTH);
    cols = cols % 2 === 0 ? cols : cols + 1;
    const colWidth = Math.floor(availableSpace / cols);
    const rowHeight = colWidth;
    let currentIndex = 0,
      currentX = 0,
      currentY = 0;
    const gaugeDefaultCols = Math.max(
      Math.round(kGAUGE_MIN_WIDTH / colWidth),
      1
    );
    const gaugeDefaultRows = Math.max(
      Math.round(kGAUGE_MIN_WIDTH / colWidth),
      1
    );
    const layout: Layout[] = [];
    schemas.forEach((s) => {
      if (s.Chart.schema.chartType === "gauge") {
        if (currentX + gaugeDefaultCols > cols) {
          const inSameRow = layout.filter((it) => it.y === currentY);
          const eachSize = (cols - currentX) / inSameRow.length;
          inSameRow.forEach((it) => {
            const index = layout.findIndex((l) => l.i === it.i);
            layout[index].w = layout[index].w + eachSize;
            if (layout[index].x !== 0) {
              layout[index].x = layout[index].x + eachSize;
            }
          });
          currentX = 0;
          currentY += gaugeDefaultRows;
        }

        layout.push({
          i: s.Device.key + "_dashboard",
          x: currentX,
          y: currentY,
          w: gaugeDefaultCols,
          h: gaugeDefaultRows,
          minW: gaugeDefaultCols,
          minH: gaugeDefaultRows,
        });
        currentIndex++;
        currentX += gaugeDefaultCols;
        if (currentX >= cols) {
          currentX = 0;
          currentY += gaugeDefaultRows;
        }
      }
    });
    return {
      layout,
      cols,
      rowHeight,
      colWidth,
    };
  }
}
