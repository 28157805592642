import { FC, useEffect, useState } from "react";

import { AppStateComponent } from "./State";
import { Skeleton } from "antd";
import { AppTaskState } from "../../../app/domain/type/State";
import { ResponsiveContainer, ResponsiveContainerProps } from "./Container";

type Props = {
  state?: AppTaskState<any> | null;
} & ResponsiveContainerProps;
export const ResponsiveStateContainer: FC<Props> = ({
  state,
  children,
  ...props
}) => {
  const [lastState, setLastState] = useState<AppTaskState<any> | undefined>(
    undefined
  );

  useEffect(() => {
    if (!!state) setLastState(state);
  }, [state]);

  return !!lastState?.hasError ? (
    <AppStateComponent
      title={"Ocurrió un error"}
      description={lastState.error?.message!!}
    />
  ) : (
    <ResponsiveContainer {...props}>
      {!!lastState?.loading ? <Skeleton active /> : children}
    </ResponsiveContainer>
  );
};
