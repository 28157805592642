import { lighten, toRgba, toHex, hasBadContrast, readableColor } from "color2k";
import { DarkThemeGenerator } from "../implementation/DarkThemeGenerator";
import { ThemeConfig } from "antd";
import { LightThemeGenerator } from "../implementation/LightThemeGenerator";
import { PrimaryMenuThemeGenerator } from "../implementation/PrimaryMenuThemeGenerator";
import { SecondaryMenuThemeGenerator } from "../implementation/SecondaryMenuThemeGenerator";
import {
  AppColorPalette,
  AppRegularColorPalette,
  kDEFAULT_REGULAR_COLOR_PALETTE,
} from "../type/AppPalette";

export class AppThemeUseCase {
  static byDefaultUseDarkTheme() {
    if (!!window.matchMedia) {
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      return mediaQuery.matches;
    }
    return false;
  }

  private static parseColor(color: string, defaultColor: string) {
    try {
      return toRgba(color);
    } catch (e) {
      return toRgba(defaultColor);
    }
  }

  static getPrimaryThemeConfig(
    isDark: boolean,
    palette: AppColorPalette
  ): ThemeConfig {
    return isDark
      ? new DarkThemeGenerator(palette).build()
      : new LightThemeGenerator(palette).build();
  }

  static getMenuThemeConfig(palette: AppColorPalette): ThemeConfig {
    return new PrimaryMenuThemeGenerator(palette).build();
  }

  static getSecondaryMenuThemeConfig(palette: AppColorPalette): ThemeConfig {
    return new SecondaryMenuThemeGenerator(palette).build();
  }

  static getReadableColor(color: string) {
    if (hasBadContrast("white", "decorative", color)) {
      return "#424242";
    } else {
      return "#FFFFFF";
    }
  }
  static redableColorIsDark(color: string) {
    return this.getReadableColor(color) === "#424242";
  }

  static createPalette(palette: AppRegularColorPalette): AppColorPalette {
    const primaryColor = this.parseColor(
      palette.colorPrimary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorPrimary
    );
    const secondaryColor = this.parseColor(
      palette.colorSecondary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorSecondary
    );
    const tertiaryColor = this.parseColor(
      palette.colorTertiary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorTertiary
    );
    const quaternaryColor = this.parseColor(
      palette.colorQuaternary,
      kDEFAULT_REGULAR_COLOR_PALETTE.colorQuaternary
    );
    const primaryTextColor = lighten(this.getReadableColor(primaryColor), 0.1);
    const secondaryTextColor = lighten(primaryTextColor, 0.1);
    const tertiaryTextColor = this.getReadableColor(tertiaryColor);
    const quaternaryTextColor = this.getReadableColor(quaternaryColor);
    const backgroundColor = lighten(primaryColor, 0.6);
    const paperColor = lighten(primaryColor, 0.63);
    const paperColorSecondary = lighten(secondaryColor, 0.59);
    return {
      colorPrimary: primaryColor,
      colorSecondary: secondaryColor,
      colorTertiary: tertiaryColor,
      colorQuaternary: quaternaryColor,
      textColorOnPrimary: primaryTextColor,
      textColorSecondary: secondaryTextColor,
      textColorHeader: tertiaryColor,
      textColorSubtitle: primaryTextColor,
      textColorBody: primaryTextColor,
      textColorOnSecondary: this.getReadableColor(secondaryColor),
      textColorOnTertiary: tertiaryTextColor,
      textColorOnQuaternary: quaternaryTextColor,
      headerColor: primaryColor,
      headerTextColor: this.getReadableColor(primaryColor),
      backgroundColor,
      textColorOnBackground: this.getReadableColor(backgroundColor),
      paperColor,
      textColorOnPaper: this.getReadableColor(paperColor),
      paperColorSecondary,
      textColorOnPaperSecondary: this.getReadableColor(paperColorSecondary),
    };
  }
}
