import { FC, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faServer,
  faCloudDownload,
  faTimeline,
  faInfoCircle,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";
import { Vehicle } from "../../domain/type/Vehicle";
import {
  TabContainer,
  TabContainerItem,
} from "../../../../core/presentation/component/TabContainer";
import { useDashboardHeader } from "../../../../core/layout/dashboard/presentation/hook/Header";
import { VehicleGatewayTab } from "./tabs/TabGateway";
import { VehicleLogsTab } from "./tabs/logs/TabLogs";
import { VehicleHistoryTab } from "./tabs/history/TabHistory";
import { VehicleDashboardTab } from "./tabs/dashboard/TabDashboard";
import { VehicleMainTab } from "./tabs/TabMain";

type Props = {
  vehicle: Vehicle;
};

const items: TabContainerItem[] = [
  {
    key: "dashboard",
    icon: <FontAwesomeIcon icon={faChartSimple} />,
    label: "Dashboard",
  },
  {
    key: "logs",
    icon: <FontAwesomeIcon icon={faCloudDownload} />,
    label: "Reportes",
  },

  {
    key: "history",
    icon: <FontAwesomeIcon icon={faTimeline} />,
    label: "Histórico",
  },
  {
    key: "main",
    icon: <FontAwesomeIcon icon={faInfoCircle} />,
    label: "Detalles",
  },
  {
    key: "gateway",
    icon: <FontAwesomeIcon icon={faServer} />,
    label: "Gateway",
  },
];

export const VehicleDataComponent: FC<Props> = ({ vehicle }) => {
  const { setTitle } = useDashboardHeader();

  useEffect(() => {
    setTitle("Vehículo");
  }, []);

  const onSelected = useCallback((key: string) => {
    switch (key) {
      case "gateway":
        return <VehicleGatewayTab vehicle={vehicle} />;
      case "logs":
        return <VehicleLogsTab vehicle={vehicle} />;
      case "history":
        return <VehicleHistoryTab vehicle={vehicle} />;
      case "dashboard":
        return <VehicleDashboardTab vehicle={vehicle} />;
      default:
        return <VehicleMainTab vehicle={vehicle} />;
    }
  }, []);
  return (
    <TabContainer
      items={items}
      defaultSelectedKey={"dashboard"}
      onSelected={onSelected}
    />
  );
};
