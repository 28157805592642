import { RemoteSourceResponse } from "../../domain/type/Source";
import { BaseRemoteSource } from "../../domain/model/Remote";
import { ApiService } from "../../../app/service/RemoteClient";
import { EntityPicture } from "../../domain/type/EntityPicture";

export class RemoteEntityPictureSource extends BaseRemoteSource {
  static async getEntityPicture(
    name: string
  ): Promise<RemoteSourceResponse<EntityPicture>> {
    try {
      const response = await ApiService.postWithAuth("/entity_picture/get", {
        name,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
