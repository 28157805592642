import { FC, useMemo } from "react";
import { Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { GeocodeAddress } from "nominatim-browser";

type Props = {
  address: GeocodeAddress;
};

export const AddressView: FC<Props> = ({ address }) => {
  const shortAddress = useMemo(() => {
    const r = [];
    if (!!address.city) r.push(address.city);
    else if (!!address.city_district) r.push(address.city_district);
    else if (!!address.state) r.push(address.state);
    if (!!address.suburb) r.push(address.suburb);
    else if (!!address.neighbourhood) r.push(address.neighbourhood);
    return r.join(", ");
  }, [address]);

  const details = useMemo(() => {
    const r = [];
    if (address.city) r.push(["Ciudad", address.city]);
    if (address.city_district) r.push(["Distrito", address.city_district]);
    if (address.state) r.push(["Estado", address.state]);
    if (address.suburb) r.push(["Colonia", address.suburb]);
    if (address.neighbourhood) r.push(["Barrio", address.neighbourhood]);
    if (address.postcode) r.push(["Código postal", address.postcode]);
    if ((address as any).quarter)
      r.push(["Urbanización", (address as any).quarter]);
    if ((address as any).road) r.push(["Calle", (address as any).road]);
    if (address.construction) r.push(["Construcción", address.construction]);
    if (address.house_number) r.push(["Número", address.house_number]);
    return r;
  }, [address]);

  return (
    <div className="flex flex-col gap-1">
      <Typography.Text type="secondary" className="m-0 p-0">
        {shortAddress}
      </Typography.Text>
      <details>
        <summary className="flex">
          <Typography.Text
            type="success"
            className="m-0 p-0 flex flex-row gap-1 items-center"
          >
            <FontAwesomeIcon icon={faEye} />
            Ver detalles
          </Typography.Text>
        </summary>
        <div className="flex flex-col gap-1 mt-2 rounded-xl p-2 bg-neutral-100">
          {details.map((it, i) => (
            <div key={`det-${i}`} className="flex flex-row gap-2">
              <Typography.Text type="secondary" className="m-0 p-0">
                {it[0]}
              </Typography.Text>
              <Typography.Text>{it[1]}</Typography.Text>
            </div>
          ))}
        </div>
      </details>
    </div>
  );
};
