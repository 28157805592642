export class LocalTokenUseCase {
  private static at: string = "";

  static saveAccessToken(token: string) {
    this.at = token;
  }

  static getAccessToken() {
    return this.at && this.at.length > 1 ? `Bearer ${this.at}` : undefined;
  }

  static saveRefreshToken(token: string) {
    sessionStorage.setItem("REMEMBERME", token);
  }

  static getRefreshToken() {
    return sessionStorage.getItem("REMEMBERME");
  }

  static clearTokens() {
    this.at = "";
    sessionStorage.removeItem("REMEMBERME");
  }
}
