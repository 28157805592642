import { FC } from "react";
import { isEmpty } from "lodash";
import { Card, Typography } from "antd";
import { VehicleDeviceData } from "../../../../../../core/domain/type/DeviceData";
import { AppEmptyContentStateComponent } from "../../../../../../core/presentation/component/State";
import { DeviceDataRender } from "../../../../../../core/presentation/component/charts/Render";
type Props = {
  deviceData: VehicleDeviceData[] | null;
};

export const DataViewer: FC<Props> = ({ deviceData }) => {
  return !deviceData || isEmpty(deviceData) ? (
    <AppEmptyContentStateComponent
      description={
        "No se encontraron datos para mostrar. Seleccione otro rango de fecha. "
      }
    ></AppEmptyContentStateComponent>
  ) : (
    <ChartsItemsRenderer deviceData={deviceData} />
  );
};

const ChartsItemsRenderer: FC<Required<Props>> = ({ deviceData }) => {
  return (
    <div className={"w-full flex flex-col gap-2"}>
      {deviceData!!.map((it, i) => (
        <Card key={`log-${it.variable.Device.key}`}>
          <Typography.Paragraph>
            {it.variable.Device.description && (
              <Typography.Text>
                {it.variable.Device.description}
              </Typography.Text>
            )}
            <br />
            <Typography.Text type={"secondary"}>
              {it.variable.Device.key}
            </Typography.Text>
          </Typography.Paragraph>
          <DeviceDataRender
            id={it.variable.Device.key}
            data={it.data}
            title={it.variable.Device.description}
            schema={it.variable.Chart.schema}
          />
        </Card>
      ))}
    </div>
  );
};
