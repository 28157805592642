import { RemoteSourceResponse } from "../../domain/type/Source";
import { BaseRemoteSource } from "../../domain/model/Remote";
import {
  GatewayLocation,
  VehicleDeviceData,
} from "../../domain/type/DeviceData";
import { ApiService } from "../../../app/service/RemoteClient";

export class RemoteDeviceDataSource extends BaseRemoteSource {
  static async getDeviceData(
    gatewayKey: string,
    devices: Array<string>,
    dateStart: Date,
    dateEnd: Date,
    order: "ASC" | "DESC"
  ): Promise<RemoteSourceResponse<VehicleDeviceData[]>> {
    try {
      const gateway = await ApiService.postWithAuth("/device_data/list", {
        gatewayKey,
        devices,
        dateStart,
        dateEnd,
      });
      this.checkResponseCredentials(gateway);
      return gateway.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
  static async downloadDeviceData(
    gatewayKey: string,
    device: string[],
    dateStart: Date,
    dateEnd: Date,
    type: string
  ): Promise<void> {
    try {
      const gateway = await ApiService.downloadWithAuth(
        `/device_data/list-download/${gatewayKey}/${device}/${dateStart}/${dateEnd}`
      );
      this.checkResponseCredentials(gateway);
    } catch (e) {
      throw this.parseError(e);
    }
  }
  static async getDeviceLocation(
    gatewayKey: string,
    date: Date
  ): Promise<RemoteSourceResponse<GatewayLocation[]>> {
    try {
      const gateway = await ApiService.postWithAuth(
        "/device_data/list-location",
        {
          gatewayKey,
          date,
        }
      );
      this.checkResponseCredentials(gateway);
      return gateway.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
