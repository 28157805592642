import { useState } from "react";
import { Vehicle } from "../domain/type/Vehicle";
import { VehicleRepository } from "../data/repository/Vehicle";
import { AppTaskState } from "../../../app/domain/type/State";
import { AppTaskStateG } from "../../../app/domain/model/State";

export function useVehicleViewModel() {
  const [fetchListState, setFetchListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [vehicleList, setVehicleList] = useState<Vehicle[] | null>(null);
  const [fetchVehicleState, setFetchVehicleState] =
    useState<AppTaskState<boolean> | null>(null);
  const [vehicle, setVehicle] = useState<Vehicle | null>();

  async function fetchList(id: number) {
    if (fetchListState?.loading) return;
    setFetchListState(AppTaskStateG.loading());
    try {
      const list = await VehicleRepository.getVehicleList();
      if (list.ok) {
        setVehicleList(list.data!!);
        setFetchListState(AppTaskStateG.success(true));
      } else setFetchListState(AppTaskStateG.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchListState(AppTaskStateG.error(error));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  async function fetchVehicle(id: number) {
    if (fetchVehicleState?.loading) return;
    setFetchVehicleState(AppTaskStateG.loading());
    try {
      const customer = await VehicleRepository.getVehicle(id);
      if (customer.ok) {
        setFetchVehicleState(AppTaskStateG.success(true));
        setVehicle(customer.data!!);
      } else {
        setFetchVehicleState(
          AppTaskStateG.error(new Error(customer.message!!))
        );
      }
    } catch (e: any) {
      setFetchVehicleState(AppTaskStateG.error(e));
    }
  }

  function onFetchVehicleStateReceived() {
    setFetchVehicleState(null);
  }

  return {
    fetchListState,
    vehicleList,
    fetchList,
    onFetchListStateReceived,
    fetchVehicleState,
    fetchVehicle,
    onFetchVehicleStateReceived,
    vehicle,
  };
}
