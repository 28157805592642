import { RouteObject } from "react-router-dom";

export class RouteGenerator {
  build(): Array<RouteObject> {
    throw new Error("Not implemented!");
  }

  mix(...generators: Array<RouteGenerator>): Array<RouteObject> {
    const b = generators.map((it) => it.build()).flat();
    return [...this.build(), ...b];
  }
}
