import { DashboardPaths } from "../implementation/Paths";
import { DashboardAllowedRoutes } from "../type/AllowedRoutes";
import { DashboardPathsType } from "../type/Paths";

export class DashboardPathsUseCase {
  static getPathsFromAllowedRoutes(
    allowedRoutes?: DashboardAllowedRoutes
  ): DashboardPathsType {
    const allowedPaths: DashboardPathsType = {};
    if (!!allowedRoutes) {
      if (allowedRoutes.vehicle_list)
        allowedPaths.vehicle_list = DashboardPaths.vehicle_list;
      if (allowedRoutes.vehicle_info)
        allowedPaths.vehicle_p_id = DashboardPaths.vehicle_p_id;
      if (allowedRoutes.customer_info)
        allowedPaths.customer = DashboardPaths.customer;
      if (allowedRoutes.gateway_list)
        allowedPaths.gateway_list = DashboardPaths.gateway_list;
      if (allowedRoutes.gateway_info)
        allowedPaths.gateway_p_id = DashboardPaths.gateway_p_id;
      if (allowedRoutes.user_list)
        allowedPaths.user_list = DashboardPaths.user_list;
      if (allowedRoutes.user_info)
        allowedPaths.user_p_id = DashboardPaths.user_p_id;
      if (allowedRoutes.overview)
        allowedPaths.overview = DashboardPaths.overview;
    }
    return allowedPaths;
  }
}
