import { RemoteCustomerUserSource } from "../source/Remote";

export class CustomerUserRepository {
  static async getUserList() {
    return await RemoteCustomerUserSource.getCustomerUserList();
  }

  static async getUser(id: number) {
    return await RemoteCustomerUserSource.getUser(id);
  }
}
