import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRefresh,
  faHashtag,
  faTag,
  faTextSlash,
  faAt,
  faCalendar,
  faIdCard,
  faUserTag,
  faToggleOn,
  faBuilding,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useCustomerUserViewModel } from "./ViewModel";
import { useDashboardHeader } from "../../../core/layout/dashboard/presentation/hook/Header";
import { ResponsiveStateContainer } from "../../../core/presentation/component/StateContainer";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";
import {
  DescriptionsItem,
  DescriptionsList,
} from "../../../core/presentation/component/DescriptionsList";
import { DateUtil } from "../../../core/util/Date";

export const CustomerUserPage: FC = () => {
  const { id } = useParams();
  const { customerUser, fetchUserState, fetchUser, onFetchUserStateReceived } =
    useCustomerUserViewModel();
  const { setTitle } = useDashboardHeader();

  const fetchUserData = () => {
    void fetchUser(Number(id));
  };

  useEffect(() => {
    fetchUserData();
    setTitle("Usuario de Cliente");
  }, []);

  useEffect(() => {
    if (!!fetchUserState && !fetchUserState.loading) {
      if (fetchUserState.hasError) {
        notification.error({
          message: "Error al obtener el usuario para el cliente",
        });
      }
      onFetchUserStateReceived();
    }
  }, [fetchUserState]);

  return (
    <ResponsiveStateContainer
      state={fetchUserState}
      className={"w-full h-fit overflow-hidden"}
    >
      <CustomBreadCrumb
        items={[
          {
            pathname: "/dashboard/customers",
            title: "Clientes",
            icon: <FontAwesomeIcon icon={faPeopleGroup} />,
          },
          {
            pathname: `/dashboard/customers/${customerUser?.Customer.id}?tab=users`,
            title: customerUser?.Customer.businessName,
          },
          {
            active: true,
            title: customerUser?.username,
          },
        ]}
      />
      <AppLoader loading={!!fetchUserState && fetchUserState.loading} />

      <ContentHeader
        title={`@${customerUser?.username}`}
        subtitle={customerUser?.Customer.businessName}
        extra={
          <Button.Group>
            <Button
              type={"default"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchUserData}
            >
              Actualizar
            </Button>
          </Button.Group>
        }
      />
      <DescriptionsList rowKey={"user"}>
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faHashtag} />}
          title={"ID"}
          label={customerUser?.id}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faBuilding} />}
          title={"Cliente"}
          label={customerUser?.Customer.businessName}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Nombre(s)"}
          label={customerUser?.name}
          itemKey={"name"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Apellido(s)"}
          label={customerUser?.lastname}
          itemKey={"lastname"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTextSlash} />}
          title={"Nombre de usuario"}
          label={customerUser?.username}
          itemKey={"username"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faAt} />}
          title={"Email Principal"}
          label={customerUser?.corporateEmail}
          itemKey={"corporateEmail"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faAt} />}
          title={"Email Secundario"}
          label={customerUser?.email}
          itemKey={"email"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faCalendar} />}
          title={"Cumpleaños"}
          label={DateUtil.fastFormatDate(
            customerUser?.birthDate!!,
            "dd MMM yyyy"
          )}
          itemKey={"birthDate"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faCalendar} />}
          title={"Miembro desde"}
          label={DateUtil.fastFormatDate(
            customerUser?.createdAt!!,
            "dd MMM yyyy"
          )}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faIdCard} />}
          title={"DNI"}
          label={customerUser?.dni}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faUserTag} />}
          title={"Tipo de Usuario"}
          label={customerUser?.Role?.label}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faToggleOn} />}
          title={"Estado"}
          label={
            customerUser?.enabled
              ? "Usuario habilitado"
              : "Usuario deshabilitado"
          }
          itemKey={"enabled"}
        />
      </DescriptionsList>
    </ResponsiveStateContainer>
  );
};
