import { ThemeConfig } from "antd/lib";
import { AppAntThemeGenerator } from "../model/ThemeGenerator";
import { theme } from "antd";

export class SecondaryMenuThemeGenerator extends AppAntThemeGenerator {
  build(): ThemeConfig {
    const { colorSecondary, textColorOnSecondary } = this.palette;
    return {
      algorithm: theme.compactAlgorithm,
      token: {
        fontFamily: "'Quicksand', sans-serif",
      },
      components: {
        Menu: {
          itemSelectedBg: colorSecondary,
          itemSelectedColor: textColorOnSecondary,
          colorBgElevated: "#424242",
          colorBgBase: "transparent",
          colorBgContainer: "transparent",
          fontFamily: "'Quicksand', sans-serif",
        },
      },
    };
  }
}
