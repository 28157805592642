import { GatewayDeviceSelection } from "../../domain/type/GatewayDevice";
import { RemoteGatewayDeviceSource } from "../source/DeviceRemote";

export class GatewayDeviceRepository {
  static async getGatewayDevices(
    gatewayId: number,
    selection?: GatewayDeviceSelection
  ) {
    return await RemoteGatewayDeviceSource.getGatewayDevices(
      gatewayId,
      selection
    );
  }
}
