import { FC, useEffect } from "react";
import { useDashboardHeader } from "../../../core/layout/dashboard/presentation/hook/Header";
import { useAppCustomer } from "../../../app/hook/Customer";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";
import { CustomerMain } from "./component/Main";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";

export const CustomerPage: FC = () => {
  const { setTitle } = useDashboardHeader();
  const { customer } = useAppCustomer();
  useEffect(() => {
    setTitle(customer?.businessName);
  }, [customer]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-hidden"}>
      <CustomBreadCrumb
        items={[
          {
            title: "...",
            active: true,
          },
        ]}
      />
      <ContentHeader title={customer?.businessName || "Información"} />
      <CustomerMain />
    </ResponsiveContainer>
  );
};
