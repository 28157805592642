import { FC } from "react";
import { BaseLinearChart } from "./BaseLinear";
import { ChartData1, ChartSchemaDefinition } from "../../../domain/type/Chart";

type Props = {
  tooltipTitle: string;
  items: Array<ChartData1<number>>;
  schema: ChartSchemaDefinition;
  id: string;
};

export const LineChart: FC<Props> = (props) => {
  return <BaseLinearChart {...props} type={"line"} />;
};
