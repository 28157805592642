import { ItemType } from "antd/lib/menu/hooks/useItems";
import { DashboardPathsType } from "../type/Paths";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingColumns,
  faChartBar,
  faServer,
  faTruckFast,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

export class DashboardMenuUseCase {
  static getMenuItems(paths: DashboardPathsType): ItemType<MenuItemType>[] {
    const items: ItemType<MenuItemType>[] = [];

    if (!!paths.overview) {
      items.push({
        key: paths.overview,
        label: "Inicio",
        icon: <FontAwesomeIcon icon={faChartBar} />,
      });
    }
    if (!!paths.vehicle_list) {
      items.push({
        key: paths.vehicle_list,
        label: "Flota",
        icon: <FontAwesomeIcon icon={faTruckFast} />,
      });
    }
    if (!!paths.gateway_list) {
      items.push({
        key: paths.gateway_list,
        label: "Gateways",
        icon: <FontAwesomeIcon icon={faServer} />,
      });
    }
    if (!!paths.user_list) {
      items.push({
        key: paths.user_list,
        label: "Usuarios",
        icon: <FontAwesomeIcon icon={faUserGroup} />,
      });
    }
    if (!!paths.customer) {
      items.push({
        key: paths.customer,
        label: "Cliente",
        icon: <FontAwesomeIcon icon={faBuildingColumns} />,
      });
    }

    return items;
  }
}
