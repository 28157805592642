import { RemoteGatewaySource } from "../source/Remote";

export class GatewayRepository {
  static async getVehicleGatewayList(vehicleId: number) {
    return await RemoteGatewaySource.getVehicleGateways(vehicleId);
  }

  static async getAllGateways() {
    return await RemoteGatewaySource.getAllGateways();
  }

  static async getGateway(id: number) {
    return await RemoteGatewaySource.getGateway(id);
  }
}
