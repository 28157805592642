import { io } from "socket.io-client";

import { hostConfig } from "./RemoteClient";
import { LocalTokenUseCase } from "../domain/usecase/Token";

const socket = io(`${hostConfig.host}`, {
  autoConnect: false,
  query: {
    token: LocalTokenUseCase.getAccessToken(),
  },
});

export class SocketIOService {
  static async socketConnect(uid: number) {
    if (!socket || socket.disconnected) {
      socket.disconnect();
      if (!!socket.io.opts && !!socket.io.opts.query) {
        socket.io.opts.query = {
          token: LocalTokenUseCase.getAccessToken(),
          uid,
        };
      }
      socket.connect();
    }
  }

  static async socketDisconnect() {
    if (socket && socket.connected) {
      socket.disconnect();
    }
  }

  static async socketOn(event: string, listener: (data: any) => void) {
    socket.on(event, listener);
  }

  static async socketOff(event: string, listener: (data: any) => void) {
    socket.off(event, listener);
  }

  static async socketSend<T = any>(eventName: string, body: T) {
    socket.emit(eventName, body);
  }
}
